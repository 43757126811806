
import getWallet from "./wallet";
import getContract from "./contract";
import axios from 'axios';
import { ethers } from "ethers";
import { fetchEvent } from "./requestsAxios";
const contractAddress = "TJpqgh8tLA1iFkG8xTSEMrBL1uL1zKbMLg";

export const delay = (timer=1500) => {
    console.log(timer)
    return new Promise((res, rej) => {
        setTimeout(res(true), timer)
    })
}

export const sleep = ms => new Promise(r => setTimeout(r, ms));

export const getActivities = async (userId) => {
    const tronWeb = await getWallet();
    if(tronWeb && tronWeb.defaultAddress != false) {
        const response = await tronWeb.getEventResult(contractAddress ,{eventName:"TreePayout",size:50, onlyConfirmed: true});

        if(response.length == 0) return [];

        let userPayouts = response.filter(r => r.result.receiverId == userId);

        let lastResult = response[response.length - 1];
        while(lastResult.fingerprint) {
            const nextResponse = await tronWeb.getEventResult(contractAddress, {
                eventName:"TreePayout",
                size:50, 
                onlyConfirmed: true,
                fingerprint: lastResult.fingerprint
            });
            if(nextResponse.length == 0) break;

            lastResult = nextResponse[nextResponse.length - 1];
            userPayouts.concat(nextResponse.filter(r => r.result.receiverId == userId))
            await sleep(10000)
        }
        
        userPayouts = userPayouts.map(u => u.result);
        return userPayouts;
    }
}

export const getDirectPayouts = async (userId) => {
    const tronWeb = await getWallet();
    if(tronWeb && tronWeb.defaultAddress != false) {
        // const response = await tronWeb.getEventResult(contractAddress ,{eventName:"DirectPaid",size:50, onlyConfirmed: true});
        const response = await fetchEvent("DirectPaid")
        if(response.length == 0) return [];
        let directPayouts = response.filter(r => r.to == userId);

        // let lastResult = response[response.length - 1];
        // while(lastResult.fingerprint) {
        //     const nextResponse = await tronWeb.getEventResult(contractAddress, {
        //         eventName:"DirectPaid",
        //         size:50, 
        //         onlyConfirmed: true,
        //         fingerprint: lastResult.fingerprint
        //     });
        //     if(nextResponse.length == 0) break;
            
        //     lastResult = nextResponse[nextResponse.length - 1];
        //     directPayouts.concat(nextResponse.filter(r => r.result.to == userId))

        //     await sleep(5000)
        // }

        // directPayouts = directPayouts.map(d => d.result);
        return directPayouts;
    }
}

export const getTreePayouts = async (userId) => {
    const tronWeb = await getWallet();
    if(tronWeb && tronWeb.defaultAddress != false) {
        const response = await tronWeb.getEventResult(contractAddress ,{eventName:"TreePayout",size:50, onlyConfirmed: true});

        if(response.length == 0) return [];

        let directPayouts = response.filter(r => r.result.receiverId == userId);

        let lastResult = response[response.length - 1];
        while(lastResult.fingerprint) {
            const nextResponse = await tronWeb.getEventResult(contractAddress, {
                eventName:"TreePayout",
                size:50, 
                onlyConfirmed: true,
                fingerprint: lastResult.fingerprint
            });
            if(nextResponse.length == 0) break;
            
            lastResult = nextResponse[nextResponse.length - 1];
            directPayouts.concat(nextResponse.filter(r => r.result.receiverId == userId))
            await sleep(5000)
        }

        directPayouts = directPayouts.map(d => d.result);
        return directPayouts;
    }
}

export const getLastDayTreePayoutsAmount = async () => {
    const tronWeb = await getWallet();
    if(tronWeb && tronWeb.defaultAddress != false) {
        
        let date;
        let currentDate = new Date();
        let currentDateInSeconds = currentDate.getTime() / 1000; // epoch
        
        let lastDay = new Date(currentDate.setDate(currentDate.getDate() - 1));
        let lastDayInSeconds = lastDay.getTime() / 1000;
        date = Math.round(lastDayInSeconds * 1000);

        const response = await tronWeb.getEventResult(contractAddress ,{eventName:"TreePayout",size:200, onlyConfirmed: true, sinceTimestamp: date, sort: 'block_timestamp'});
        
        if(response.length == 0) return 0;

        let directPayouts = response;

        let lastResult = response[response.length - 1];
        while(lastResult.fingerprint) {
            const nextResponse = await tronWeb.getEventResult(contractAddress, {
                eventName:"TreePayout",
                size:200, 
                onlyConfirmed: true,
                fingerprint: lastResult.fingerprint,
                sinceTimestamp: date, sort: 'block_timestamp'
            });
            if(nextResponse.length == 0) break;
            
            lastResult = nextResponse[nextResponse.length - 1];

            directPayouts = directPayouts.concat(nextResponse)

            if(directPayouts.length >= 800) break;
        }

        directPayouts = directPayouts.map(d => d.result.amount);
        const sum = directPayouts.reduce((partialSum, a) => {
            const number = ethers.BigNumber.from(a).toNumber() / 1000000
            return partialSum + number
        }, 0);

        return sum;
    }
}

export const getLastDayDirectPayoutsAmount = async () => {
    const tronWeb = await getWallet();
    if(tronWeb && tronWeb.defaultAddress != false) {
        
        let date;
        let currentDate = new Date();
        let currentDateInSeconds = currentDate.getTime() / 1000; // epoch
        
        let lastDay = new Date(currentDate.setDate(currentDate.getDate() - 1));
        let lastDayInSeconds = lastDay.getTime() / 1000;
        date = Math.round(lastDayInSeconds * 1000);

        const response = await tronWeb.getEventResult(contractAddress ,{eventName:"DirectPaid",size:200, onlyConfirmed: true, sinceTimestamp: date, sort: 'block_timestamp'});
        
        if(response.length == 0) return [];

        let directPayouts = response;

        let lastResult = response[response.length - 1];
        while(lastResult.fingerprint) {
            const nextResponse = await tronWeb.getEventResult(contractAddress, {
                eventName:"DirectPaid",
                size:200, 
                onlyConfirmed: true,
                fingerprint: lastResult.fingerprint,
                sinceTimestamp: date, sort: 'block_timestamp'
            });
            if(nextResponse.length == 0) break;
            
            lastResult = nextResponse[nextResponse.length - 1];

            directPayouts = directPayouts.concat(nextResponse)
            if(directPayouts.length >= 50) break;
            await sleep(5000)
        }

        directPayouts = directPayouts.map(d => d.result.amount);
        const sum = directPayouts.reduce((partialSum, a) => {
            const number = ethers.BigNumber.from(a).toNumber() / 1000000
            return partialSum + number
        }, 0);

        return sum;
    }
}

export const getRegistrationActivities = async () => {
    const tronWeb = await getWallet();
    if(tronWeb && tronWeb.defaultAddress != false) {
        const response = await tronWeb.getEventResult(contractAddress ,{eventName:"Registration",size:200, onlyConfirmed: true});

        if(response.length == 0) return [];

        let registrationActivities = response.map(r => r.result);
                
        return registrationActivities;
    }
}

export const getLastDayRegistrationActivitiesCount = async () => {
    const tronWeb = await getWallet();
    if(tronWeb && tronWeb.defaultAddress != false) {
        let date;
        let currentDate = new Date();
        let currentDateInMiliSeconds = Math.round(currentDate.getTime()); // epoch
        
        let lastDay = new Date(currentDate.setDate(currentDate.getDate() - 1));
        let lastDayInMiliSeconds = Math.round(lastDay.getTime());
        date = currentDateInMiliSeconds - lastDayInMiliSeconds;
        
        const response = await tronWeb.getEventResult(contractAddress ,{eventName:"Registration",size:200, onlyConfirmed: true, sinceTimestamp: lastDayInMiliSeconds, sort: 'block_timestamp'});
        
        let data = response;

        if(response.length == 0) return 0;

        let lastResult = response[response.length - 1];
        while(lastResult.fingerprint) {
            const nextResponse = await tronWeb.getEventResult(contractAddress, {
                eventName:"Registration",
                size:200, 
                onlyConfirmed: true,
                fingerprint: lastResult.fingerprint,
                sinceTimestamp: lastDayInMiliSeconds, sort: 'block_timestamp'
            });
            if(nextResponse.length == 0) break;
            
            lastResult = nextResponse[nextResponse.length - 1];

            data = data.concat(nextResponse);

            if(data.length >= 50) break;
            await sleep(5000)
        }


        let registrationActivities = data.map(r => r.result);
                
        return registrationActivities.length;
    }
}

const getTransactions = async (limit) => {
    const contract = await getContract();
    try{
        if(limit) {
            const response = await axios.get(
                `https://api.trongrid.io/v1/contracts/${contract.address}/transactions?only_confirmed=true&limit=${limit}&search_internal=false`,
                // {
                //     headers: {
                //         'TRON-PRO-API-KEY': "b00aa4b9-84b0-40b6-865f-d527537f1ba5"
                //     }
                // }
            );
            // const response = await axios.get(`https://nile.trongrid.io/v1/contracts/${contract.address}/transactions?only_confirmed=true&limit=${limit}&search_internal=false`);
            const transactions = response.data.data;
            return transactions;
        } else {
            const response = await axios.get(
                `https://api.trongrid.io/v1/contracts/${contract.address}/transactions?only_confirmed=true&limit=${limit}&search_internal=false`,
                {
                    headers: {
                        'TRON-PRO-API-KEY': "b00aa4b9-84b0-40b6-865f-d527537f1ba5"
                    }
                }
            );
            // const response = await axios.get(`https://nile.trongrid.io/v1/contracts/${contract.address}/transactions?only_confirmed=true&limit=${limit}&search_internal=false`);
            const transactions = response.data.data;
            return transactions;
        }
    } catch(err) {
        // alert(err)
        return new Error("Max Connections Exceeded!")
    }
};

export const getLastActivities = async () => {
    const tronWeb = await getWallet();
    if(tronWeb && tronWeb.defaultAddress !== false) {
        const transactions = await getTransactions(10);
        let activities = [];
        if(transactions) {
            for(let i = 0; i < transactions.length; i++) {
                const events = await tronWeb.getEventByTransactionID(transactions[i].txID);
                // const result = await axios.get(`https://nile.trongrid.io/v1/transactions/${transactions[i].txID}/events`);
                const registerEvent = events.find(e => e.name === 'Registration');
                const treePayoutEvent = events.find(e => e.name === 'TreePayout');
                let data = {};
                if(registerEvent) {
                    const result = registerEvent.result;
                    data.isRegistrationEvent = true;
                    data.txId = transactions[i].txID;
                    data.id = result.newUserId;
                    const date = getDate(result.time);
                    data.time = date.toDateString();
                    activities.push(data);
                }

                data = {};
                if(treePayoutEvent) {
                    const result = treePayoutEvent.result;
                    data.isRegistrationEvent = false;
                    data.id = result.receiverId;
                    data.txId = transactions[i].txID;
                    data.amount = result.amount;
                    const date = getDate(result.time);
                    data.time = date.toDateString();
                    activities.push(data);
                }
            }
            return activities;
        }
    }
}

export const getPayoutActivities = async (userId, level) => {
    const tronWeb = await getWallet(true);
    let activites = [];
    const directPayouts = await getDirectPayouts(userId);
    const treePayouts = await getTreePayouts(userId);
    for(let i = 0; i < 400; i++) {
        if(treePayouts[i] && treePayouts[i].receiverId === userId && treePayouts[i].level === level) {
            let data = {};
            const date = getDate(treePayouts[i].time);
            data.time = date.toDateString();
            data.isDirectIncome = false;
            data.amount = +treePayouts[i].amount / 1000000;
            data.senderId = treePayouts[i].senderId;
            activites.push(data);
        }

        if(directPayouts[i] && directPayouts[i].to == userId && directPayouts[i].level == level) {
            let data = {};
            const date = getDate(directPayouts[i].timeNow);
            data.time = date.toDateString();
            data.isDirectIncome = true;
            data.amount = +directPayouts[i].amount / 1000000;
            data.senderId = directPayouts[i].from;
            activites.push(data);
        }
    }
    activites.sort((a,b) => new Date(a.time) - new Date(b.time))
    return activites;
}

export const getPartnersActivities = async (userId, showCount = 10) => {
    const tronWeb = await getWallet(true);
    if(tronWeb && tronWeb.defaultAddress != false) {
        const response = await fetchEvent("Registration")
        console.log(response[0])
        // return [];
        // // const response = await tronWeb.getEventResult(contractAddress ,{eventName:"Registration",size:100, onlyConfirmed: true, order_by: "timestamp,asc"});
        
        // if(response.length == 0) return [];
        let partners = response.filter(r => r.orignalRefId == userId);
        
        // // let lastResult = response[response.length - 1];
        // // while(lastResult.fingerprint) {
        //     try {
        // //         const nextResponse = await tronWeb.getEventResult(contractAddress, {
        // //             eventName:"Registration",
        // //             size:100, 
        // //             onlyConfirmed: true,
        // //             fingerprint: lastResult.fingerprint,
        // //             order_by: "timestamp,asc"
        // //         });
        // //         if(nextResponse.length == 0) break;
    
        //         // lastResult = nextResponse[nextResponse.length - 1];
        //         partners.concat(nextResponse.filter(r => r.result.orignalRefId == userId))
        //         console.log(userId, partners.length)
        //         if ( partners.length >= showCount ) {
        //             // break;
        //         }
        //     } catch(err) {
        //         console.log(err)
        //         // break;
        //     }
            
        //     await sleep(7000)
        // }
        // partners = partners.map(u => u);
        console.log(partners)
        return partners;
    }
}

export const getLastDayIncome = async () => {
    const treePayouts = await getLastDayTreePayoutsAmount();
    const directPayouts = await getLastDayDirectPayoutsAmount();
    return directPayouts + treePayouts;
}

export const getLastDayCount = async () => {
    return await getLastDayRegistrationActivitiesCount();
}

function getDate(x) {
    const myDate = new Date(x * 1000);
    return myDate;
 }