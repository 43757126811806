import {getPoolClaimContract, getPoolContract} from "./contract";
import getWallet, {getConnected} from "./wallet";

export const getPools = async () => {
    try {
        return [
            {
                collected: 9612 * 1e6,
                startedAt: 1680411437,
                endAt: 1682868731,
                perShare: 0.003121,
                address: "TABG3fhj44CSGrfZ9VEC7swNGbLdNHxhWW",
                claimed: 0,
                gracePeriod: 86400 * 30,
                isActive: false
            },
            {
                collected: 933840 * 1e6,
                startedAt: 1672482423 + 2*( 86400 * 30 ),
                endAt: 1680411437,
                perShare: 38.053789,
                address: "TXTuZ1aChd1nkCraoeGJhxer2XHb1YUsAD",
                claimed: 0,
                gracePeriod: 86400 * 28,
                isActive: false
            },
            {
                collected: 2716560 * 1e6,
                startedAt: 1672482423 + ( 86400 * 30 ),
                endAt: 1672482423 + 2*( 86400 * 30 ),
                perShare: 101.492938,
                address: "TAiJd2i25B7ADr6gAMdt4KvmXfzxpAtuDf",
                claimed: 0,
                gracePeriod: 86400 * 30,
                isActive: false
            },
            {
                collected: 2859690 * 1e6,
                startedAt: 1672482423000 / 1000,
                endAt: 1672482423 + ( 86400 * 30 ),
                perShare: 315.113135,
                address: "TYuUQ4uSdFU1CW7RBy65Utffyca6gf8UBN",
                claimed: 0,
                gracePeriod: 86400 * 30,
                isActive: false
            },
            {
                collected: 6642900 * 1e6,
                startedAt: 1670275623000 / 1000,
                endAt: 1672482423000 / 1000,
                perShare: 315.113135,
                address: "TVj9b7K2XZ9W1xrvMg57vQUKGRCTpHBvRH",
                claimed: 0,
                gracePeriod: 86400 * 30,
                isActive: false
            }
        ]
        const poolContract = await getPoolContract()
        const allPools = await poolContract.getPoolLength().call()
        const tronWeb = await getWallet()

        let count = 0
        let poolData = []
        for ( let i = allPools-1; i >= 0; i -- ) {
            if ( count <= 10 ) {
                const round = await poolContract.rounds(i).call()

                const poolInstance = await getPoolClaimContract( round.roundAddress )
                if ( poolInstance ) {
                    const perShare = await poolInstance.getLivePerShareValue().call()
                    const walletAddress = await getConnected()
                    const user = await poolInstance.user(walletAddress).call()
                    const isActive= await poolInstance.isActive().call()
                    const gracePeriod = await poolInstance.getGracePeriod().call()

                    poolData.push({
                        collected: round.collected,
                        startedAt: round.start,
                        endAt: round.end,
                        perShare: parseFloat(perShare / 1e6),
                        address: tronWeb.address.fromHex(round.roundAddress),
                        claimed: parseFloat(user.returned / 1e6),
                        gracePeriod: gracePeriod,
                        isActive
                    })
                }
            }
            count ++;
        }

        return poolData
    } catch (e) {
        console.log(e)
        return []
    }
}

export const livePool = async ( walletAddress ) => {
    try {
        const tronWeb = await getWallet()
        const poolContract = await getPoolContract()
        const allPools = await poolContract.getPoolLength().call()
        const liveRoundId = allPools - 1;

        let returnObj = {
            collected: "0",
            endAt: 0,
            totalShares: "-",
            myShare: "-",
            perShare: "-",
            address: ""
        }

        const round = await poolContract.rounds(liveRoundId).call()
        const poolInstance = await getPoolClaimContract( round.roundAddress.toString() )
        // toast.info("pool loaded")
        const perShare = await poolInstance.getLivePerShareValue().call()
        const totalCollected = await poolInstance.totalCollected().call()
        const totalShares = await poolInstance.totalShares().call()

        if ( walletAddress ) {
            const user = await poolInstance.user( walletAddress ).call()
            returnObj['myShare'] = parseInt(user.shares)
        }

        returnObj.perShare = perShare / 1e6;
        returnObj.collected = totalCollected / 1e6;
        returnObj.totalShares = parseInt(totalShares)
        returnObj.endAt = round.end
        returnObj.address = tronWeb.address.fromHex(round.roundAddress)

        return returnObj
    } catch (e) {
        // toast.error("error")
        alert("error "+ e)
        console.log(`error: ${e}`)
        return {
            collected: "0",
            endAt: 0,
            totalShares: "-",
            myShare: "-",
            perShare: "-"
        }
    }
}