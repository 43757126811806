import {useCallback, useEffect, useMemo, useState} from "react";
import {getPoolClaimContract, getPoolContract} from "../../../../services/contract";
import {toast} from "react-toastify";
import getWallet, {getConnected} from "../../../../services/wallet";


export const usePoolDetails = ({pool}) => {

    const [ poolOverview, setPoolOverview ] = useState({isLoading: true})

    useEffect(() => {
        (async() => {
            try {
                if (pool) {
                    const poolDetail = await getPoolClaimContract(pool)
                    const oxPool = await getPoolContract()
                    const pInfo = await oxPool.poolDetails(pool).call()

                    if (poolDetail) {
                        const perShare = await poolDetail.getLivePerShareValue().call()
                        const totalCollected = await poolDetail.totalCollected().call()
                        const totalShares = await poolDetail.totalShares().call()
                        const isActive = await poolDetail.isActive().call()
                        const start = await poolDetail.start().call()
                        const end = await poolDetail.end().call()
                        const gracePeriod = await poolDetail.getGracePeriod().call()

                        let poolOverviewInst = {
                            collected: totalCollected / 1e6,
                            startedAt: start,
                            endAt: parseInt(end),
                            totalShares: parseInt(totalShares),
                            perShare: perShare / 1e6,
                            isActive,
                            isLoading: false
                        }

                        poolOverviewInst['poolId'] = parseInt(pInfo.roundId) + 1
                        const walletAddress = await getConnected()

                        if (walletAddress) {
                            const user = await poolDetail.user(walletAddress).call()
                            poolOverviewInst['myShare'] = parseInt(user.shares)
                            poolOverviewInst['received'] = user.received
                            poolOverviewInst['claimed'] = user.returned / 1e6
                            poolOverviewInst['gracePeriod'] = parseInt(gracePeriod)
                        } else {
                            poolOverviewInst['myShare'] = "0"
                        }

                        setPoolOverview(poolOverviewInst)
                    }
                }
            } catch (e) {
                console.log(e)
                return {}
            }
        })()
    }, [pool, setPoolOverview])

    return useMemo( () => { return poolOverview }, [poolOverview]);
}

export const usePoolCallback = ({pool}) => {
    const updateShare = useCallback( async () => {
        if ( pool ) {
            const poolDetail = await getPoolClaimContract( pool )
            const transactionPromise = poolDetail.updateMyShare().send({
                feeLimit: 10_000_000_000,
                shouldPollResponse: true
            })
            await toast.promise(transactionPromise, {
                pending: "Updating in process",
                success: "Share updated",
                error: "Update failed ",
            })
        } else {
            return new Promise((res, reject) => {
                reject("Contract not initialized")
            })
            // toast.error("Contract not initialized")
        }
    }, [pool])

    const claimShare = useCallback(async ( ) => {
        if ( pool ) {
            const tronW = await getWallet()
            const trxBalance = await tronW.trx.getBalance( pool )
            const walletAddress = await getConnected()
            const poolDetail = await getPoolClaimContract( pool )
            const user = await poolDetail.user(walletAddress).call()
            const userShare = user.shares;
            const perShare = await poolDetail.getLivePerShareValue().call()

            const totalUserTrx = userShare * perShare;
            console.log(user)

            if ( trxBalance < totalUserTrx ) {
                alert("Err Code - 001. Contact Support")
                // reject("Err Code - 001. Contact Support");
                return;
            }
            
            const transactionPromise = poolDetail.claim().send({
                feeLimit: 10_000_000_000,
                shouldPollResponse: true
            })
            await toast.promise(transactionPromise, {
                pending: "Claiming in process",
                success: "Claimed successfully",
                error: "Claim failed ",
            })
        } else {
            return new Promise((res, reject) => {
                reject("Contract not initialized")
            })
            // toast.error("Contract not initialized")
        }
    }, [pool] )

    return {
        updateShare,
        claimShare
    }
}