import Slot from "../tree/slot";
import levelPrices from "../../../services/levelPrice";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {usePoolCallback, usePoolDetails} from "./hooks/usePoolDetails";
import moment from "moment";
import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css"

export const PoolOverview = () => {
    const params = useParams()
    const navigate = useNavigate()
    const poolDetails = usePoolDetails({pool: params.poolID})
    const { updateShare, claimShare } = usePoolCallback({pool:params.poolID})
    const [ headLine, setHeadLine ] = useState("")
    const [ isLoading, setLoading ] = useState(false)
    const [ poolFinished, setPoolFinished ] = useState(false)
    const [ isClaimed, setClaimed ] = useState(false)

    useEffect(() => {
        if ( !params.poolID || (!poolDetails.isLoading && !poolDetails.poolId) ) {
            navigate("../pools/")
        }
    }, [params, poolDetails])

    useEffect(() => {
        const now = Date.now()
        const end = parseFloat(poolDetails?.endAt) * 1000

        if ( poolDetails?.isActive ) {
            setHeadLine(`The pool will be ended at ${moment(end).format("DD MMM YYYY hh:mm:ss a")}`)
        } else if(end && !poolDetails?.isActive) {
            setHeadLine(`The pool was ended on ${moment(end).format("DD MMM YYYY hh:mm:ss a")}`)
            setPoolFinished ( true )

            console.log("this end", (end + (poolDetails?.gracePeriod * 1000)))

            if ( (end + (poolDetails?.gracePeriod * 1000)) > now) {
                setClaimed(false)
                if ( poolDetails?.received && poolDetails?.received > 0 ) {
                    setClaimed(true)
                }
            }
            else {
                // console.log("thisCalled", end)
                if ( poolDetails?.received && poolDetails?.received > 0 ) {
                    setClaimed(true)
                } else {
                    setClaimed(true)
                }
            }
        }
    }, [poolDetails, setPoolFinished, setClaimed])

    const updateShareCallback = useCallback(() =>{
        setLoading(true)
        updateShare().then((res) => {
            setLoading(false)
        }).catch((err) => {
            console.log(err)
            toast.error(err.toString())
            setLoading(false)
        })
    }, [updateShare])

    const claimShareCallback = useCallback(() => {
        claimShare().then((res) => {
            setLoading(false)
        }).catch((err) => {
            console.log(err)
            toast.error(err.toString())
            setLoading(false)
        })
    }, [claimShare])

    return (<>
        <div className="flex flex-shrink w-full flex-col items-center min-h-screen bg-main pt-20 sm:pt-14">
            {/* <ToastContainer position="bottom-right" /> */}
            <div className="flex relative flex-1 py-2 pt-8.5 flex-col justify-between w-full px-10 sm:px-0 sm:pt-7.5">
                {/* Top Panel, Matrix levels and all activies inside below div */}
                <div className="flex flex-col w-full space-y-10 sm:space-y-5">

                    {/* Top panel of Dashboard mobile view inside below div */}
                    <div
                        className="flex w-full items-start justify-between space-x-10 lg:space-x-2.5 sm:space-x-0 sm:space-y-5 sm:flex-col sm:px-5">
                        <div className="flex items-start flex-shrink-0 sm:flex-shrink">
                            <div
                                className="flex-shrink-0 relative w-30 h-30 rounded-full cursor-pointer sm:w-15 sm:h-15">
                                <div className="profile-logo-box w-26 h-16 sm:h-14 sm:w-14 false rounded-full p-2">
                                    <img
                                        src="/images/logo.png" />
                                </div>

                            </div>
                            <div className="flex flex-col items-start ml-6 h-full justify-center my-auto">
                                <div className="flex justify-center items-center mb-1 sm:mb-2.5">
                                    <span className="text-white font-bold text-3xl mr-2.5 sm:text-xl cursor-pointer showContractId">
                                        Founder's Pool #{poolDetails?.poolId}
                                    </span>
                                </div>
                                <div className="flex flex-col items-start text-white">
                                    {headLine}
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Top panel of Dashboard mobile view inside above div */}

                    {/* Top panel Partners, Team, Profit ration, Profits View inside below div */}
                    <div className="flex w-full mt-15 sm:mt-5 sm:px-5">
                        <div className="flex w-full flex-wrap lg:flex-col">
                            <div className="flex mr-10 space-x-10 lg:mr-0 lg:mt-10 sm:mt-5 sm:space-x-5 lg:order-2 sm:overflow-auto sm:w-full">
                                {
                                    isClaimed && <div className="lg:flex-grow flex flex-col p-5 bg-black-light rounded desktop-infoblock-base sm:mobile-infoblock-base theme-card-bg">
                                        <div className="flex items-center"><span
                                            className="text-base sm:text-sm sm:whitespace-nowrap">Claimed</span>
                                        </div><span
                                        className="text-2xl font-bold sm:text-xl mt-5 sm:mt-2.5">
                                    {
                                        poolDetails?.claimed
                                    } TRX
                                </span>
                                    </div>
                                }
                                {
                                    poolFinished && !isClaimed && <div className="lg:flex-grow flex flex-col p-5 bg-black-light rounded desktop-infoblock-base sm:mobile-infoblock-base theme-card-bg">
                                        <div className="flex items-center"><span
                                            className="text-base sm:text-sm sm:whitespace-nowrap">Claimable</span>
                                        </div><span className="text-2xl font-bold sm:text-xl mt-5 sm:mt-2.5"
                                                    id="yourTeamTreeCount">
                                        {
                                            Math.round(poolDetails?.myShare * poolDetails?.perShare, 2)
                                        } TRX
                                        </span>
                                        <button
                                            className="flex justify-center items-center text-center text-base font-bold text-white rounded-mini sm:text-sm outline-none bg-black-500 px-5 py-3 hover:bg-yellow-900 active:bg-active-red-600"
                                            onClick={claimShareCallback}
                                            disabled={!poolFinished || isLoading}
                                        >
                                            Claim
                                        </button>
                                    </div>
                                }
                                <div className="lg:flex-grow flex flex-col p-5 bg-black-light rounded desktop-infoblock-base sm:mobile-infoblock-base theme-card-bg">
                                    <div className="flex items-center"><span
                                        className="text-base sm:text-sm sm:whitespace-nowrap">Total Shares</span>
                                    </div><span
                                    className="text-2xl font-bold sm:text-xl mt-5 sm:mt-2.5">
                                    {
                                        poolDetails?.totalShares
                                    }
                                </span>

                                </div>
                                <div className="lg:flex-grow flex flex-col p-5 bg-black-light rounded desktop-infoblock-base sm:mobile-infoblock-base theme-card-bg">
                                    <div className="flex items-center"><span
                                        className="text-base sm:text-sm sm:whitespace-nowrap">My Shares</span>
                                    </div><span className="text-2xl font-bold sm:text-xl mt-5 sm:mt-2.5"
                                                id="yourTeamTreeCount">
                                    {
                                        poolDetails?.myShare
                                    }
                                </span>
                                    {
                                        !poolFinished && <button
                                        className="flex justify-center items-center text-center text-base font-bold text-white rounded-mini sm:text-sm outline-none bg-black-500 px-5 py-3 hover:bg-yellow-900 active:bg-active-red-600"
                                        onClick={updateShareCallback}
                                        disabled={isLoading || poolFinished}
                                    >
                                        Update
                                    </button>
                                    }

                                </div>
                                <div className="lg:flex-grow sm:max-w-full flex flex-col p-5 bg-black-light rounded desktop-infoblock-base sm:mobile-infoblock-base theme-card-bg">
                                    <div className="flex items-center"><span
                                        className="text-base sm:text-sm sm:whitespace-nowrap">Per Share</span>
                                    </div><span
                                    className="text-2xl font-bold sm:text-xl mt-5 sm:mt-2.5">
                                    {
                                        poolDetails?.perShare
                                    } TRX
                                            </span>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex w-full mt-15 sm:mt-5 sm:px-5">
                        <div className="flex w-full lg:flex-col">
                            <div className="flex flex-1 sm:max-w-full overflow-hidden w-full relative justify-start items-end bg-black-light rounded lg:max-w-full desktop-infoblock-chart-base theme-card-bg">
                                <div
                                    className="flex-shrink-0 flex flex-col p-5 rounded desktop-infoblock-base sm:mobile-infoblock-base">
                                    <div className="flex items-center"><span
                                        className="text-base sm:text-sm sm:whitespace-nowrap">Total Collected</span>
                                    </div><span className="text-2xl font-bold sm:text-xl mt-5 sm:mt-2.5">
                                                    {
                                                        poolDetails?.collected
                                                    } TRX</span>

                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Top panel Partners, Team, Profit ration, Profits View inside above div */}
                </div>
                {/* Top Panel, Matrix levels and all activies inside above div */}
                <footer
                    className="flex lg:flex-col items-center mt-auto justify-between lg:justify-start lg:items-start py-10 w-full lg:p-5 lg:pb-9 pb-0 lg:pb-0 z-10">
                    <div className="hidden lg:block mb-7.5">
                        <div className="flex space-x-5 w-full"><a
                            className="w-6 h-6 sm:w-7 sm:h-7 flex justify-center items-center rounded-full bg-white-100 hover:bg-white-300"
                            target="_blank" href="https://t.me/oxcashnetwork"><svg
                            className="w-3 h-3 sm:w-4 sm:h-4" viewBox="0 0 14 12" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path clipRule="evenodd"
                                  d="M11.915.776.841 5.168a.4.4 0 0 0 .03.755l2.79.864 1.133 3.503a.4.4 0 0 0 .653.17l1.64-1.523 3.11 2.29a.4.4 0 0 0 .627-.231l2.166-9.294a.8.8 0 0 0-1.075-.926Zm-6.483 6.74-.346 2.072-.8-2.901 7.816-5.1-6.67 5.929Z"
                                  fill="#fff"></path>
                        </svg></a>

                        </div>
                    </div><span className="text-white-500 text-xs font-normal lg:mb-2.5">© 2022 All Rights
                                Reserved</span>
                </footer>
            </div>
        </div>
        </>
    )
}