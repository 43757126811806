import { ethers } from "ethers";
import getContract from "./contract";
import getOldContract from "./oldContract";
import levelPrices from "./levelPrice";

import getWallet from "./wallet";
import {toast} from "react-toastify";

async function delayService(sec=5000) {
  return new Promise((resolve) => {
    setTimeout(() => resolve(true), sec)
  })
}

export class TransactionService {
  
  constructor(userAddress) {
    this.userAddress = userAddress;
  }

  buyLevel = async (level) => {
    try {
      const contract = await getContract();
      const userInfoResponse = await contract.userInfos(this.userAddress).call();
      const userLastLevel = ethers.BigNumber.from(userInfoResponse["levelBought"]).toNumber();
      // const userId = ethers.BigNumber.from(userInfoResponse["id"]).toNumber();

      if (userLastLevel+1 < level) {
        throw new Error("You have to buy previous level first");
      }
      let callValue = 0;
      const oldContract = await getOldContract();
      const old_userInfoResponse = await oldContract.userInfos(this.userAddress).call();
      const levelBought = old_userInfoResponse['levelBought'];
      const old_isAlreadyRegistered = ethers.BigNumber.from(old_userInfoResponse["id"]).toNumber() > 0;

      const tronWeb = await getWallet();
      const userBalance = await tronWeb.trx.getBalance(this.userAddress);
      const levelPrice = levelPrices[level-1] + "000000";
      console.log(levelBought, level)

      let migration = await tronWeb.contract().at(
        "TRUfMpqLr5nGr3FjGbtbFPzRaBWajndY7U"
      )
    
      let migrationStatus = await migration.isMigrationEnabled().call()

      if(old_isAlreadyRegistered && levelBought >= level && migrationStatus) {
        callValue = parseFloat(levelPrice) * 20 / 100;
      } else {
        callValue = levelPrice
      }
      if (userBalance < callValue) {
        throw new Error("User has Insufficient balance");
      }
      // }

      const energyFees = ethers.BigNumber.from(await contract.energyFees(level).call()).toNumber();
      const isEnergyBought = await contract.isEnergyBought(this.userAddress, level).call();
      if(!isEnergyBought) {
        await contract.buyEnergyLevel(level).send({
          callValue: energyFees,
          shouldPollResponse: true
        })
        await delayService()
        let isEnergyBoughtUpdated = await contract.isEnergyBought(this.userAddress, level).call();
        while ( !isEnergyBoughtUpdated ) {
          isEnergyBoughtUpdated = await contract.isEnergyBought(this.userAddress, level).call();
        }
      }

      const resources = await tronWeb.trx.getAccountResources("TV97nfdJqFCg3CshX4KchKZ8dz4dV6GsHR")
      const availableResources = resources.EnergyLimit - resources.EnergyUsed

      if ( availableResources < 20_000_000 ) {
        alert("Contract's energy is been refilling.. Please try again after");
        return;
      }

      await contract.buyLevel(level).send({
        callValue: callValue,
        feeLimit: 10_000_000_000,
        shouldPollResponse: true
      });

      await delayService()
      let userInfoUpdated = await contract.userInfos(this.userAddress).call()
      while ( !userInfoUpdated.joined ) {
        userInfoUpdated = await contract.userInfo(this.userAddress).call()
      }
      return true;
    } catch (error) {
      throw error;
    }
  }

  register = async (refId) => {
    try {
      const contract = await getContract();
      const tronWeb = await getWallet()
      // alert("a b  cd d")
      const refAddress = await contract.getAddressById(refId).call();
      // alert("Address ")
      const refInfoResponse = await contract.userInfos(tronWeb.address.fromHex(refAddress.toString())).call();
      // alert(refInfoResponse)
      const isValidRef = ethers.BigNumber.from(refInfoResponse["id"]).toNumber() > 0;

      if(!isValidRef) {
        throw new Error("Invalid Upline Id. There is no referrer with this id match");
      }
      // alert("A b")
      const userInfoResponse = await contract.userInfos(this.userAddress).call();
      // alert("A b c")
      const isAlreadyRegistered = ethers.BigNumber.from(userInfoResponse["id"]).toNumber() > 0;

      if(isAlreadyRegistered) {
        throw new Error("User is already registered");
      }
      let callValue = 0;

      // alert(this.userAddress)
      const oldContract = await getOldContract();
      
      // alert(oldContract.address)
      const old_userInfoResponse = await oldContract.userInfos(this.userAddress).call();
      // alert("ths B")
      const old_isAlreadyRegistered = parseInt(old_userInfoResponse["id"]) > 0;

      // alert(old_isAlreadyRegistered)
      
      // const tronWeb = await getWallet();
      const userBalance = await tronWeb.trx.getBalance(this.userAddress);
      const levelPrice = levelPrices[0] + "000000";

      let migration = await tronWeb.contract().at(
        "TRUfMpqLr5nGr3FjGbtbFPzRaBWajndY7U"
      )
    
      let migrationStatus = await migration.isMigrationEnabled().call()

      if(old_isAlreadyRegistered && migrationStatus) {
          callValue = parseFloat(levelPrice) * 20 / 100
      } else {
        callValue = levelPrice
      }
      if (userBalance < callValue) {
        throw new Error("User has Insufficient balance");
      }
      // }

      const energyFees = ethers.BigNumber.from(await contract.energyFees(1).call()).toNumber();
      const isEnergyBought = await contract.isEnergyBought(this.userAddress, 1).call();
      console.log(isEnergyBought)
      if(!isEnergyBought) {

        if ( toast ) {
          const promiseEnergyBuy = contract.buyEnergyLevel(1).send({
            callValue: energyFees,
            shouldPollResponse: true
          })
          
          await toast.promise(promiseEnergyBuy, {
            pending: "Receiving energy fee for registration",
            success: "Energy purchased",
            error: "Purchase failed ",
          });
          await delayService()

          let isEnergyBoughtUpdated = await contract.isEnergyBought(this.userAddress, 1).call();
          let checkCount = 0
          while ( !isEnergyBoughtUpdated ) {
            if ( checkCount == 2 ) {
              break;
            }
            isEnergyBoughtUpdated = await contract.isEnergyBought(this.userAddress, 1).call();
            await delayService()
            checkCount += 1
          }
        } else{
          await contract.buyEnergyLevel(1).send({
            callValue: energyFees,
            shouldPollResponse: true
          })
        }
      }

      const resources = await tronWeb.trx.getAccountResources("TV97nfdJqFCg3CshX4KchKZ8dz4dV6GsHR")
      const availableResources = resources.EnergyLimit - resources.EnergyUsed

      if ( availableResources < 20_000_000 ) {
        alert("Contract's energy is been refilling.. Please try again after");
        return;
      }
      
      await contract.regUser(refId).send({
        callValue: callValue,
        feeLimit: 10_000_000_000,
        shouldPollResponse: true
      });
      let userId = await contract.getIdByAddress(this.userAddress).call();
      await delayService()
      let userIdCount = 0
      while ( userId != 0 ) {
        if ( userIdCount === 2 ) {
          break
        }
        userId = await contract.getIdByAddress(this.userAddress).call();
        await delayService()
        userIdCount += 1
      }

      if ( userId === 0 ) {
        throw new Error(
          "Registration failed, check transaction logs!"
        )
      }

      return userId;
    } catch (error) {
      throw error;
    }
  }
}