const Slot = (props) => {
  const ids = props.ids;
  const index = props.index;
  const levelPrices = props.levelPrices;
  const reinvestCounts = props.reinvestCounts;

  const getIdClass = (id) => {
    return id == 0 ? "bg-white-100" : "bg-white";
  };

  return props.isActive ? (
    <a
      onClick={() => props.onSlotClick(ids[0], index + 1)}
      // to={`/dashboard/${props.userId}/${index + 1}`}
      key={index}
    >
      <div className="relative overflow-hidden flex flex-col w-180px min-h-180px sm:w-158px sm:min-h-180px rounded-small p-5 m-2 justify-between  bg-gray-900 hover:bg-gray-800  sm:space-y-1.5">
        {/* Top Level Price area */}
        <div className="flex w-full justify-between">
          <div className="flex space-x-1.5 items-center">
            <span className="text-white-500 text-base sm:text-sm slot-title">
              Slot
              {index + 1}
            </span>
          </div>
          <span className="flex items-center text-white text-base sm:text-sm">
            <img
              src="/user/img/tron-coin-icon.png"
              className="w-3 h-3 mr-1.5"
              alt=""
            />
            {levelPrices[index]}
          </span>
        </div>
        {/* Middle Tree area */}
        <div className="flex flex-col -ml-2.5 -mr-2.5 ">
          <div className="flex w-full">
            <div className="flex w-full justify-evenly items-start flex-1">
              {/* Left subtree */}
              <div className="flex flex-col w-full justify-evenly items-center space-y-1.5">
                {/* Left subtree Top id */}
                <div className="relative">
                  <div
                    className={"rounded-full w-7.5 h-7.5 " + getIdClass(ids[1])}
                    title={ids[1]}
                    id="lvl1left"
                  ></div>
                </div>
                {/* Left subtree Remaining ids */}
                <div className="flex w-full">
                  <div className="flex w-full justify-evenly items-start flex-1">
                    <div className="flex flex-col w-full justify-evenly items-center space-y-1.5">
                      {/* id 3 in below div */}
                      <div className="relative">
                        <div
                          className={
                            "rounded-full w-5 h-5 " + getIdClass(ids[3])
                          }
                          title={ids[3]}
                          id="lvl1left2"
                        ></div>
                      </div>

                      <div className="flex w-full">
                        <div className="flex w-full justify-evenly items-start flex-1">
                          <div className="flex flex-col w-full justify-evenly items-center space-y-1.5">
                            <div className="relative">
                              <div
                                className={
                                  "rounded-full w-2.5 h-2.5 " +
                                  getIdClass(ids[7])
                                }
                                title={ids[7]}
                                id="lvl1left4"
                              ></div>
                            </div>
                            <div className="flex w-full">
                              <div className="flex w-full justify-evenly items-start flex-1">
                                <div className="flex flex-col w-full justify-evenly items-center space-y-1.5">
                                  <div className="relative">
                                    <div
                                      className={
                                        "rounded-full w-1 h-1 " +
                                        getIdClass(ids[15])
                                      }
                                      title={ids[15]}
                                      id="lvl1left8"
                                    ></div>
                                  </div>
                                </div>
                                <div className="flex flex-col w-full justify-evenly items-center space-y-1.5">
                                  <div className="relative">
                                    <div
                                      className={
                                        "rounded-full w-1 h-1 " +
                                        getIdClass(ids[16])
                                      }
                                      title={ids[16]}
                                      id="lvl1left9"
                                    ></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="flex flex-col w-full justify-evenly items-center space-y-1.5">
                            <div className="relative">
                              <div
                                className={
                                  "rounded-full w-2.5 h-2.5 " +
                                  getIdClass(ids[8])
                                }
                                title={ids[8]}
                                id="lvl1left5"
                              ></div>
                            </div>
                            <div className="flex w-full">
                              <div className="flex w-full justify-evenly items-start flex-1">
                                <div className="flex flex-col w-full justify-evenly items-center space-y-1.5">
                                  <div className="relative">
                                    <div
                                      className={
                                        "rounded-full w-1 h-1 " +
                                        getIdClass(ids[17])
                                      }
                                      title={ids[17]}
                                      id="lvl1left10"
                                    ></div>
                                  </div>
                                </div>
                                <div className="flex flex-col w-full justify-evenly items-center space-y-1.5">
                                  <div className="relative">
                                    <div
                                      className={
                                        "rounded-full w-1 h-1 " +
                                        getIdClass(ids[18])
                                      }
                                      title={ids[18]}
                                      id="lvl1left11"
                                    ></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col w-full justify-evenly items-center space-y-1.5">
                      <div className="relative">
                        <div
                          className={
                            "rounded-full w-5 h-5 " + getIdClass(ids[4])
                          }
                          title={ids[4]}
                          id="lvl1left3"
                        ></div>
                      </div>
                      <div className="flex w-full">
                        <div className="flex w-full justify-evenly items-start flex-1">
                          <div className="flex flex-col w-full justify-evenly items-center space-y-1.5">
                            <div className="relative">
                              <div
                                className={
                                  "rounded-full w-2.5 h-2.5 " +
                                  getIdClass(ids[9])
                                }
                                title={ids[9]}
                                id="lvl1left6"
                              ></div>
                            </div>
                            <div className="flex w-full">
                              <div className="flex w-full justify-evenly items-start flex-1">
                                <div className="flex flex-col w-full justify-evenly items-center space-y-1.5">
                                  <div className="relative">
                                    <div
                                      className={
                                        "rounded-full w-1 h-1 " +
                                        getIdClass(ids[19])
                                      }
                                      title={ids[19]}
                                      id="lvl1left12"
                                    ></div>
                                  </div>
                                </div>
                                <div className="flex flex-col w-full justify-evenly items-center space-y-1.5">
                                  <div className="relative">
                                    <div
                                      className={
                                        "rounded-full w-1 h-1 " +
                                        getIdClass(ids[20])
                                      }
                                      title={ids[20]}
                                      id="lvl1left13"
                                    ></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="flex flex-col w-full justify-evenly items-center space-y-1.5">
                            <div className="relative">
                              <div
                                className={
                                  "rounded-full w-2.5 h-2.5 " +
                                  getIdClass(ids[10])
                                }
                                title={ids[10]}
                                id="lvl1left7"
                              ></div>
                            </div>
                            <div className="flex w-full">
                              <div className="flex w-full justify-evenly items-start flex-1">
                                <div className="flex flex-col w-full justify-evenly items-center space-y-1.5">
                                  <div className="relative">
                                    <div
                                      className={
                                        "rounded-full w-1 h-1 " +
                                        getIdClass(ids[21])
                                      }
                                      title={ids[21]}
                                      id="lvl1left14"
                                    ></div>
                                  </div>
                                </div>
                                <div className="flex flex-col w-full justify-evenly items-center space-y-1.5">
                                  <div className="relative">
                                    <div
                                      className={
                                        "rounded-full w-1 h-1 " +
                                        getIdClass(ids[22])
                                      }
                                      title={ids[22]}
                                      id="lvl1left15"
                                    ></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Right subtree */}
              <div className="flex flex-col w-full justify-evenly items-center space-y-1.5">
                <div className="relative">
                  <div
                    className={"rounded-full w-7.5 h-7.5 " + getIdClass(ids[2])}
                    title={ids[2]}
                    id="lvl1right"
                  ></div>
                </div>
                <div className="flex w-full">
                  <div className="flex w-full justify-evenly items-start flex-1">
                    <div className="flex flex-col w-full justify-evenly items-center space-y-1.5">
                      <div className="relative">
                        <div
                          className={
                            "rounded-full w-5 h-5 " + getIdClass(ids[5])
                          }
                          title={ids[5]}
                          id="lvl1right2"
                        ></div>
                      </div>
                      <div className="flex w-full">
                        <div className="flex w-full justify-evenly items-start flex-1">
                          <div className="flex flex-col w-full justify-evenly items-center space-y-1.5">
                            <div className="relative">
                              <div
                                className={
                                  "rounded-full w-2.5 h-2.5 " +
                                  getIdClass(ids[11])
                                }
                                title={ids[11]}
                                id="lvl1right4"
                              ></div>
                            </div>
                            <div className="flex w-full">
                              <div className="flex w-full justify-evenly items-start flex-1">
                                <div className="flex flex-col w-full justify-evenly items-center space-y-1.5">
                                  <div className="relative">
                                    <div
                                      className={
                                        "rounded-full w-1 h-1 " +
                                        getIdClass(ids[23])
                                      }
                                      title={ids[23]}
                                      id="lvl1right8"
                                    ></div>
                                  </div>
                                </div>
                                <div className="flex flex-col w-full justify-evenly items-center space-y-1.5">
                                  <div className="relative">
                                    <div
                                      className={
                                        "rounded-full w-1 h-1 " +
                                        getIdClass(ids[24])
                                      }
                                      title={ids[24]}
                                      id="lvl1right9"
                                    ></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="flex flex-col w-full justify-evenly items-center space-y-1.5">
                            <div className="relative">
                              <div
                                className={
                                  "rounded-full w-2.5 h-2.5 " +
                                  getIdClass(ids[12])
                                }
                                title={ids[12]}
                                id="lvl1right5"
                              ></div>
                            </div>
                            <div className="flex w-full">
                              <div className="flex w-full justify-evenly items-start flex-1">
                                <div className="flex flex-col w-full justify-evenly items-center space-y-1.5">
                                  <div className="relative">
                                    <div
                                      className={
                                        "rounded-full w-1 h-1 " +
                                        getIdClass(ids[25])
                                      }
                                      title={ids[25]}
                                      id="lvl1right10"
                                    ></div>
                                  </div>
                                </div>
                                <div className="flex flex-col w-full justify-evenly items-center space-y-1.5">
                                  <div className="relative">
                                    <div
                                      className={
                                        "rounded-full w-1 h-1 " +
                                        getIdClass(ids[26])
                                      }
                                      title={ids[26]}
                                      id="lvl1right11"
                                    ></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col w-full justify-evenly items-center space-y-1.5">
                      <div className="relative">
                        <div
                          className={
                            "rounded-full w-5 h-5 " + getIdClass(ids[6])
                          }
                          title={ids[6]}
                          id="lvl1right3"
                        ></div>
                      </div>
                      <div className="flex w-full">
                        <div className="flex w-full justify-evenly items-start flex-1">
                          <div className="flex flex-col w-full justify-evenly items-center space-y-1.5">
                            <div className="relative">
                              <div
                                className={
                                  "rounded-full w-2.5 h-2.5 " +
                                  getIdClass(ids[13])
                                }
                                title={ids[13]}
                                id="lvl1right6"
                              ></div>
                            </div>
                            <div className="flex w-full">
                              <div className="flex w-full justify-evenly items-start flex-1">
                                <div className="flex flex-col w-full justify-evenly items-center space-y-1.5">
                                  <div className="relative">
                                    <div
                                      className={
                                        "rounded-full w-1 h-1 " +
                                        getIdClass(ids[27])
                                      }
                                      title={ids[27]}
                                      id="lvl1right12"
                                    ></div>
                                  </div>
                                </div>
                                <div className="flex flex-col w-full justify-evenly items-center space-y-1.5">
                                  <div className="relative">
                                    <div
                                      className={
                                        "rounded-full w-1 h-1 " +
                                        getIdClass(ids[28])
                                      }
                                      title={ids[28]}
                                      id="lvl1right13"
                                    ></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="flex flex-col w-full justify-evenly items-center space-y-1.5">
                            <div className="relative">
                              <div
                                className={
                                  "rounded-full w-2.5 h-2.5 " +
                                  getIdClass(ids[14])
                                }
                                title={ids[14]}
                                id="lvl1right7"
                              ></div>
                            </div>
                            <div className="flex w-full">
                              <div className="flex w-full justify-evenly items-start flex-1">
                                <div className="flex flex-col w-full justify-evenly items-center space-y-1.5">
                                  <div className="relative">
                                    <div
                                      className={
                                        "rounded-full w-1 h-1 " +
                                        getIdClass(ids[29])
                                      }
                                      title={ids[29]}
                                      id="lvl1right14"
                                    ></div>
                                  </div>
                                </div>
                                <div className="flex flex-col w-full justify-evenly items-center space-y-1.5">
                                  <div className="relative">
                                    <div
                                      className={
                                        "rounded-full w-1 h-1 " +
                                        getIdClass(ids[30])
                                      }
                                      title={ids[30]}
                                      id="lvl1right15"
                                    ></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Bottom Reinvest */}
        <div className="flex flex-wrap space-x-2">
          <div className="flex space-x-2 items-center">
            <svg
              className="w-5 h-5 stroke-current text-white-500"
              viewBox="0 0 20 20"
              fill="none"
              stroke="#2CFF4E"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                clipRule="evenodd"
                d="M6.354 3.818a7.25 7.25 0 0 1 10.808 5.28.5.5 0 1 1-.99.137A6.25 6.25 0 0 0 4.551 7h2.115a.5.5 0 0 1 0 1H3.333a.5.5 0 0 1-.5-.5V4.167a.5.5 0 1 1 1 0v2.086a7.25 7.25 0 0 1 2.521-2.435ZM3.265 10.338a.5.5 0 0 1 .564.427A6.25 6.25 0 0 0 15.449 13h-2.116a.5.5 0 1 1 0-1H16.667a.5.5 0 0 1 .5.5v3.333a.5.5 0 1 1-1 0v-2.086a7.25 7.25 0 0 1-13.329-2.845.5.5 0 0 1 .427-.564Z"
              ></path>
            </svg>
            <span className="text-sm text-white font-normal sm:text-sm">
              {reinvestCounts[index]}
            </span>
          </div>
        </div>
      </div>
    </a>
  ) : (
    <a onClick={() => props.onBuyLevel(index+1)}>
      <div className="relative overflow-hidden flex flex-col w-180px min-h-180px sm:w-158px sm:min-h-180px rounded-small p-5 m-2 justify-between  bg-gray-900 hover:bg-gray-800  sm:space-y-1.5">
        <div className="flex w-full justify-between">
          <div className="flex space-x-1.5 items-center">
            <span className="text-white-500 text-base sm:text-sm slot-title">
              Slot {index+1}
            </span>
          </div>
          <span className="flex items-center text-white text-base sm:text-sm">
            <img
              src="img/tron-coin-icon.png"
              className="w-3 h-3 mr-1.5"
              alt=""
            />
            {levelPrices[index]}
          </span>
        </div>
        <div
          className="flex flex-col -ml-2.5 -mr-2.5 hidden"
          id="level2DivTree"
        >
          <div className="flex w-full">
            <div className="flex w-full justify-evenly items-start flex-1">
              <div className="flex flex-col w-full justify-evenly items-center space-y-1.5">
                <div className="relative">
                  <div
                    className="rounded-full w-7.5 h-7.5 bg-white-100"
                    id="lvl2left"
                  ></div>
                </div>
                <div className="flex w-full">
                  <div className="flex w-full justify-evenly items-start flex-1">
                    <div className="flex flex-col w-full justify-evenly items-center space-y-1.5">
                      <div className="relative">
                        <div
                          className="rounded-full w-5 h-5 bg-white-100"
                          id="lvl2left2"
                        ></div>
                      </div>
                      <div className="flex w-full">
                        <div className="flex w-full justify-evenly items-start flex-1">
                          <div className="flex flex-col w-full justify-evenly items-center space-y-1.5">
                            <div className="relative">
                              <div
                                className="rounded-full w-2.5 h-2.5 bg-white-100"
                                id="lvl2left4"
                              ></div>
                            </div>
                            <div className="flex w-full">
                              <div className="flex w-full justify-evenly items-start flex-1">
                                <div className="flex flex-col w-full justify-evenly items-center space-y-1.5">
                                  <div className="relative">
                                    <div
                                      className="rounded-full w-1 h-1 bg-white-100"
                                      id="lvl2left8"
                                    ></div>
                                  </div>
                                </div>
                                <div className="flex flex-col w-full justify-evenly items-center space-y-1.5">
                                  <div className="relative">
                                    <div
                                      className="rounded-full w-1 h-1 bg-white-100"
                                      id="lvl2left9"
                                    ></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="flex flex-col w-full justify-evenly items-center space-y-1.5">
                            <div className="relative">
                              <div
                                className="rounded-full w-2.5 h-2.5 bg-white-100"
                                id="lvl2left5"
                              ></div>
                            </div>
                            <div className="flex w-full">
                              <div className="flex w-full justify-evenly items-start flex-1">
                                <div className="flex flex-col w-full justify-evenly items-center space-y-1.5">
                                  <div className="relative">
                                    <div
                                      className="rounded-full w-1 h-1 bg-white-100"
                                      id="lvl2left10"
                                    ></div>
                                  </div>
                                </div>
                                <div className="flex flex-col w-full justify-evenly items-center space-y-1.5">
                                  <div className="relative">
                                    <div
                                      className="rounded-full w-1 h-1 bg-white-100"
                                      id="lvl2left11"
                                    ></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col w-full justify-evenly items-center space-y-1.5">
                      <div className="relative">
                        <div
                          className="rounded-full w-5 h-5 bg-white-100"
                          id="lvl2left3"
                        ></div>
                      </div>
                      <div className="flex w-full">
                        <div className="flex w-full justify-evenly items-start flex-1">
                          <div className="flex flex-col w-full justify-evenly items-center space-y-1.5">
                            <div className="relative">
                              <div
                                className="rounded-full w-2.5 h-2.5 bg-white-100"
                                id="lvl2left6"
                              ></div>
                            </div>
                            <div className="flex w-full">
                              <div className="flex w-full justify-evenly items-start flex-1">
                                <div className="flex flex-col w-full justify-evenly items-center space-y-1.5">
                                  <div className="relative">
                                    <div
                                      className="rounded-full w-1 h-1 bg-white-100"
                                      id="lvl2left12"
                                    ></div>
                                  </div>
                                </div>
                                <div className="flex flex-col w-full justify-evenly items-center space-y-1.5">
                                  <div className="relative">
                                    <div
                                      className="rounded-full w-1 h-1 bg-white-100"
                                      id="lvl2left13"
                                    ></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="flex flex-col w-full justify-evenly items-center space-y-1.5">
                            <div className="relative">
                              <div
                                className="rounded-full w-2.5 h-2.5 bg-white-100"
                                id="lvl2left7"
                              ></div>
                            </div>
                            <div className="flex w-full">
                              <div className="flex w-full justify-evenly items-start flex-1">
                                <div className="flex flex-col w-full justify-evenly items-center space-y-1.5">
                                  <div className="relative">
                                    <div
                                      className="rounded-full w-1 h-1 bg-white-100"
                                      id="lvl2left14"
                                    ></div>
                                  </div>
                                </div>
                                <div className="flex flex-col w-full justify-evenly items-center space-y-1.5">
                                  <div className="relative">
                                    <div
                                      className="rounded-full w-1 h-1 bg-white-100"
                                      id="lvl2left15"
                                    ></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col w-full justify-evenly items-center space-y-1.5">
                <div className="relative">
                  <div
                    className="rounded-full w-7.5 h-7.5 bg-white-100"
                    id="lvl2right"
                  ></div>
                </div>
                <div className="flex w-full">
                  <div className="flex w-full justify-evenly items-start flex-1">
                    <div className="flex flex-col w-full justify-evenly items-center space-y-1.5">
                      <div className="relative">
                        <div
                          className="rounded-full w-5 h-5 bg-white-100"
                          id="lvl2right2"
                        ></div>
                      </div>
                      <div className="flex w-full">
                        <div className="flex w-full justify-evenly items-start flex-1">
                          <div className="flex flex-col w-full justify-evenly items-center space-y-1.5">
                            <div className="relative">
                              <div
                                className="rounded-full w-2.5 h-2.5 bg-white-100"
                                id="lvl2right4"
                              ></div>
                            </div>
                            <div className="flex w-full">
                              <div className="flex w-full justify-evenly items-start flex-1">
                                <div className="flex flex-col w-full justify-evenly items-center space-y-1.5">
                                  <div className="relative">
                                    <div
                                      className="rounded-full w-1 h-1 bg-white-100"
                                      id="lvl2right8"
                                    ></div>
                                  </div>
                                </div>
                                <div className="flex flex-col w-full justify-evenly items-center space-y-1.5">
                                  <div className="relative">
                                    <div
                                      className="rounded-full w-1 h-1 bg-white-100"
                                      id="lvl2right9"
                                    ></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="flex flex-col w-full justify-evenly items-center space-y-1.5">
                            <div className="relative">
                              <div
                                className="rounded-full w-2.5 h-2.5 bg-white-100"
                                id="lvl2right5"
                              ></div>
                            </div>
                            <div className="flex w-full">
                              <div className="flex w-full justify-evenly items-start flex-1">
                                <div className="flex flex-col w-full justify-evenly items-center space-y-1.5">
                                  <div className="relative">
                                    <div
                                      className="rounded-full w-1 h-1 bg-white-100"
                                      id="lvl2right10"
                                    ></div>
                                  </div>
                                </div>
                                <div className="flex flex-col w-full justify-evenly items-center space-y-1.5">
                                  <div className="relative">
                                    <div
                                      className="rounded-full w-1 h-1 bg-white-100"
                                      id="lvl2right11"
                                    ></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col w-full justify-evenly items-center space-y-1.5">
                      <div className="relative">
                        <div
                          className="rounded-full w-5 h-5 bg-white-100"
                          id="lvl2right3"
                        ></div>
                      </div>
                      <div className="flex w-full">
                        <div className="flex w-full justify-evenly items-start flex-1">
                          <div className="flex flex-col w-full justify-evenly items-center space-y-1.5">
                            <div className="relative">
                              <div
                                className="rounded-full w-2.5 h-2.5 bg-white-100"
                                id="lvl2right6"
                              ></div>
                            </div>
                            <div className="flex w-full">
                              <div className="flex w-full justify-evenly items-start flex-1">
                                <div className="flex flex-col w-full justify-evenly items-center space-y-1.5">
                                  <div className="relative">
                                    <div
                                      className="rounded-full w-1 h-1 bg-white-100"
                                      id="lvl2right12"
                                    ></div>
                                  </div>
                                </div>
                                <div className="flex flex-col w-full justify-evenly items-center space-y-1.5">
                                  <div className="relative">
                                    <div
                                      className="rounded-full w-1 h-1 bg-white-100"
                                      id="lvl2right13"
                                    ></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="flex flex-col w-full justify-evenly items-center space-y-1.5">
                            <div className="relative">
                              <div
                                className="rounded-full w-2.5 h-2.5 bg-white-100"
                                id="lvl2right7"
                              ></div>
                            </div>
                            <div className="flex w-full">
                              <div className="flex w-full justify-evenly items-start flex-1">
                                <div className="flex flex-col w-full justify-evenly items-center space-y-1.5">
                                  <div className="relative">
                                    <div
                                      className="rounded-full w-1 h-1 bg-white-100"
                                      id="lvl2right14"
                                    ></div>
                                  </div>
                                </div>
                                <div className="flex flex-col w-full justify-evenly items-center space-y-1.5">
                                  <div className="relative">
                                    <div
                                      className="rounded-full w-1 h-1 bg-white-100"
                                      id="lvl2right15"
                                    ></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-wrap space-x-2 hidden" id="level2DivData">
          <div className="flex space-x-2 items-center">
            <svg
              className="w-5 h-5 stroke-current text-white-500"
              viewBox="0 0 16 16"
              fill="none"
              stroke="#fff"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6 7.333A2.667 2.667 0 1 0 6 2a2.667 2.667 0 0 0 0 5.333ZM2 14v-1.333A2.667 2.667 0 0 1 4.667 10h2.666A2.667 2.667 0 0 1 10 12.667V14M10.667 2.086a2.667 2.667 0 0 1 0 5.167M14 14v-1.333a2.667 2.667 0 0 0-2-2.567"
                strokeWidth="1.333"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
            </svg>
            <span className="text-sm text-white font-normal sm:text-sm">0</span>
          </div>
          <div className="flex space-x-2 items-center">
            <svg
              className="w-5 h-5 stroke-current text-white-500"
              viewBox="0 0 20 20"
              fill="none"
              stroke="#2CFF4E"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                clipRule="evenodd"
                d="M6.354 3.818a7.25 7.25 0 0 1 10.808 5.28.5.5 0 1 1-.99.137A6.25 6.25 0 0 0 4.551 7h2.115a.5.5 0 0 1 0 1H3.333a.5.5 0 0 1-.5-.5V4.167a.5.5 0 1 1 1 0v2.086a7.25 7.25 0 0 1 2.521-2.435ZM3.265 10.338a.5.5 0 0 1 .564.427A6.25 6.25 0 0 0 15.449 13h-2.116a.5.5 0 1 1 0-1H16.667a.5.5 0 0 1 .5.5v3.333a.5.5 0 1 1-1 0v-2.086a7.25 7.25 0 0 1-13.329-2.845.5.5 0 0 1 .427-.564Z"
              ></path>
            </svg>
            <span className="text-sm text-white font-normal sm:text-sm">0</span>
          </div>
        </div>
        <div className="flex w-full justify-center h-full items-center pb-8">
          <div className="px-4 py-2 text-black-800 bg-white mx-auto text-center rounded-full text-sm font-weight-500">
            <i className="las la-shopping-cart text-xl"></i>            
          </div>
          
        </div>
      </div>
    </a>
  );
};

export default Slot;
