import React, { Component } from 'react';
import withRouter from '../../helpers/withWrapper'
import { getStatsData } from '../../services/partners'
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
class Stats extends Component {
    state = {  
        data: [],
        actualLength: 0
    } 

    constructor(props) {
        super(props);
        this.state.userId = props.router.params.id;
    }
    async componentDidMount() {
        const promise = getStatsData(this.state.userId);
        
        const result = await toast.promise(promise, {
            pending: "Fetching data please wait...",
            success: "Data loaded",
            error: "Error fetching data",
        });
        this.setState({actualLength: result.length})
        this.setState({data: result});
    }
    render() { 
        return (
            <div className="flex flex-shrink w-full flex-col items-center min-h-screen bg-main pt-20 sm:pt-14">
                    <div className="flex relative flex-1 py-2 pt-8.5 flex-col justify-between w-full px-10 sm:px-0 sm:pt-7.5">
                        <main className="flex flex-1 w-full">
                            <div className="flex flex-col w-full space-y-10 sm:space-y-7.5">
                                <div className="flex flex-col">
                                    <div className="flex mb-7.5 sm:px-5 sm:justify-between"><span className="text-3xl text-white font-medium sm:text-2xl">Stats</span>
                                    </div>
                                    <div className="p-5">
                                    {
                                        this.state.actualLength !== 0 &&
                                        <span className="text-sm text-white">
                                        Loaded - {this.state.data.length} / {this.state.actualLength} ( *Note: This data is fetched from last 10,000 event transactions )
                                        </span>
                                    }
                                    </div>
                                    <div className="flex flex-col mb-5 w-full sm:px-5">
                                        <div className="flex-1 sm:rounded-none z-10 flex flex-col w-full flex-col bg-black-light rounded overflow-hidden h-full min-h-90 sm:max-h-3/4">
                                            <div className="overflow-auto">
                                                <table className="min-w-max w-full table-auto border-white-100">
                                                    <thead>
                                                        <tr className="text-white-500 text-xs font-normal border-b border-white-100">
                                                            <th className="p-4 text-left font-normal sm:p-3 ">Type</th>
                                                            <th className="p-4 text-left font-normal sm:p-3">Date</th>
                                                            <th className="p-4 text-left font-normal sm:p-3">ID</th>
                                                            {/* <th className="p-4 text-left font-normal sm:p-3">Hash</th> */}
                                                            <th className="p-4 text-left font-normal sm:p-3">From ID</th>
                                                            <th className="p-4 text-right font-normal sm:p-3">TRX</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="text-gray-600 text-sm font-light">
                                                        {this.state.data.map((d, idx) => {
                                                            return(
                                                            <tr key={idx} className="border-b border-white-100 last:border-none">
                                                            <td className="p-4 text-left whitespace-nowrap sm:p-3">
                                                                <div className="flex w-full">
                                                                    <div className="relative flex items-center justify-center rounded-full bg-white-100 w-11 h-11 sm:w-7.5 sm:h-7.5">
                                                                        <svg className="text-white h-18px w-18px " viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M10 8.667A3.333 3.333 0 1 0 10 2a3.333 3.333 0 0 0 0 6.667Z" fill="#fff" stroke="#fff" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                            <path d="M5 17v-1.667A3.333 3.333 0 0 1 8.333 12h3.334A3.333 3.333 0 0 1 15 15.333V17" fill="#fff"></path>
                                                                            <path d="M5 17v-1.667A3.333 3.333 0 0 1 8.333 12h3.334A3.333 3.333 0 0 1 15 15.333V17H5Z" stroke="#fff" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                        </svg><span className="absolute top-0 right-0 text-mini leading-3 text-white"></span>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td className=" p-4 text-left whitespace-nowrap sm:p-3">
                                                                <div className="text-white-500 text-sm ">{d.joinDate}
                                                                </div>
                                                            </td>
                                                            <td className="p-4 text-left whitespace-nowrap sm:p-3">
                                                                <div className="text-white text-sm ">{d.to}</div>
                                                            </td>
                                                            <td className="p-4 text-left whitespace-nowrap sm:p-3">
                                                                <div className="text-white text-sm ">{d.from}</div>
                                                            </td>
                                                            
                                                            <td className="p-4 text-left whitespace-nowrap sm:p-3">
                                                                <div className="text-white text-sm text-right ">{d.amount + " TRX"}</div>
                                                            </td>
                                                            </tr>)
                                                        
                                                        })}
                                                        
                                                        
                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </main>
                        <footer className="flex lg:flex-col items-center mt-auto justify-between lg:justify-start lg:items-start py-10 w-full lg:p-5 lg:pb-9 pb-0 lg:pb-0 z-10">
                            <div className="hidden lg:block mb-7.5">
                                <div className="flex space-x-5 w-full"><a className="w-6 h-6 sm:w-7 sm:h-7 flex justify-center items-center rounded-full bg-white-100 hover:bg-white-300" target="_blank" href="https://t.me/oxcashnetwork"><svg className="w-3 h-3 sm:w-4 sm:h-4" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M11.915.776.841 5.168a.4.4 0 0 0 .03.755l2.79.864 1.133 3.503a.4.4 0 0 0 .653.17l1.64-1.523 3.11 2.29a.4.4 0 0 0 .627-.231l2.166-9.294a.8.8 0 0 0-1.075-.926Zm-6.483 6.74-.346 2.072-.8-2.901 7.816-5.1-6.67 5.929Z" fill="#fff"></path>
                                        </svg></a>

                                </div>
                            </div><span className="text-white-500 text-xs font-normal lg:mb-2.5">© 2022 All Rights
                                Reserved</span>
                        </footer>
                    </div>
                </div>
        );
    }
}
 
export default withRouter(Stats);