import getWallet from "./wallet";
import abi from "../abi/xGold.json";

const contractAddressOld = "TVQiMUxYCo2s1q2uDhEtVcZ2aMu2AQq816";

const getOldContract = async () => {
    const tronWeb = await getWallet();
    let contract;
    console.log(tronWeb);
    if(tronWeb && tronWeb.defaultAddress != false) {
      contract = await tronWeb.contract().at(
        contractAddressOld
      );
    }
    return contract;
}

export default getOldContract;