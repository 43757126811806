import getWallet from "./wallet";
import abi from "../abi/xGold.json";
import poolAbi from "../abi/poolAbi.json";
import {OxPool} from "../config";
import { toast } from "react-toastify";

const contractAddress = "TJpqgh8tLA1iFkG8xTSEMrBL1uL1zKbMLg";

const getContract = async () => {
    const tronWeb = await getWallet();
    let contract;
    if(tronWeb && tronWeb.defaultAddress != false) {
      contract = await tronWeb.contract(
        abi,
        contractAddress
      );
    }
    return contract;
}

export const getPoolContract = async () => {
    const tronWeb = await getWallet();
    let contract;
    if(tronWeb && tronWeb.defaultAddress != false) {
        contract = await tronWeb.contract().at(OxPool);
    }
    return contract;
}

export const getPoolClaimContract = async ( poolAddress ) => {
    const tronWeb = await getWallet();
    let contract;
    // try {
    if(tronWeb && tronWeb.defaultAddress != false) {
        contract = await tronWeb.contract(poolAbi, tronWeb.address.fromHex(poolAddress));
    }
    // } catch(e) {
    //     toast.info(e.toString())
    // }
    return contract;
}

export default getContract;