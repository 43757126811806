import React, { Component } from "react";
import Header from "./header";
import SidePanel from "./sidePanel";
import Dashboard from "./dashboard";
import Matrix from "./matrix";
import { ethers } from 'ethers';
import getWallet from '../../services/wallet';
import withWrapper from '../../helpers/withWrapper';
import getContract from "../../services/contract";
import Stats from "./stats";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Partners from "./partners";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Pools from "./pools";
import {PoolOverview} from "./PreviousPools/PoolOverview";
import UniLevel from "./UniLevel";


class DashboardLayout extends Component {
  constructor(props) {
    super(props);
    if(props.router.params.id > 0) {
      this.state.userId = props.router.params.id;
    }
    else {
      this.state.userId = 1;
    }
  }
  state = {
    view: 'dashboard',
    isViewMode: false,
    signer: false,
    isSideBarOpen: false,
  };

  connectWallet = async () => {
    const tronWeb = await getWallet();
    if(tronWeb && tronWeb.defaultAddress.base58 !== false) {
      this.setState({ signer: true });
      return true;
    }
    return false;
  }

  handleWalletConnected = async (contract) => {
    this.setState({ signer: contract.signer });
  }

  handleConnectWallet = async () => {
    const isConnected = await this.connectWallet();
    // if(isConnected)
    //   this.notify("Wallet Connected");
  }

  handleDashboardClick = () => {
    this.props.router.navigate('/dashboard/' + this.state.userId);
    this.sideBarToggle()
  }

  handleStatsClick = () => {
    this.props.router.navigate('/stats/' + this.state.userId);
    toast.dismiss()
    this.sideBarToggle()
  }

  handlePartnersClick = () => {
    this.props.router.navigate('/partners/' + this.state.userId);
    toast.dismiss()
    this.sideBarToggle()
  }

  handleUniLevelsClick = () => {
    this.props.router.navigate('/unilevels/' + this.state.userId);
    toast.dismiss()
    this.sideBarToggle()
  }

  handlePoolsClick = () => {
    this.props.router.navigate('/pools/');
    toast.dismiss()
    this.sideBarToggle()
  }

  handleSlotClick = (id, level) => {
    this.props.router.navigate('/dashboard/' + id + '/' + level + '/0');
    toast.dismiss()
    // this.sideBarToggle()
  }

  handleDisconnect = () => {
    this.props.router.navigate('/login');
  }

  handleSetUserId = (id) => {
    this.setState({userId: id});
  }

  sideBarToggle = () => {
    this.setState({isSideBarOpen: !this.state.isSideBarOpen})
  }
 
  render() {
    return (
      <div id="__next1">
        <ToastContainer position="bottom-right" />
        <div className="relative flex bg-main-bg items-center justify-center min-h-screen min-w-full overflow-hidden">
          <Header onDisconnect={this.handleDisconnect} sideBarToggle={this.sideBarToggle} onConnectWallet={this.handleConnectWallet} signer={this.state.signer} />
          <div className="flex w-full h-full justify-center max-w-desktop-full">
            <SidePanel onDashboardClick={this.handleDashboardClick} 
                id={this.state.userId}
                onStatsClick={this.handleStatsClick}
                onPartnersClick={this.handlePartnersClick}
                onUniLevelsClick={this.handleUniLevelsClick}
                onPoolsClick={this.handlePoolsClick}
                sideBarOpened={this.state.isSideBarOpen}
            />
            <Routes>
                <Route path="dashboard/:id" element={<Dashboard 
                    onSetUserId={(id) => this.handleSetUserId(id)}
                    onSlotClick={this.handleSlotClick} isViewMode={this.state.isViewMode}
                    userId={this.state.userId} onWalletConnected={this.handleConnectWallet}
                 />} />
                <Route path="dashboard/:id/:level/:reinvest" element={<Matrix  
                    onSetUserId={(id) => this.handleSetUserId(id)}
                    router={this.props.router} level={this.state.level} 
                    userId={this.state.userId} idsOnLevel={this.state.idsOnLevel}
                />} />
                <Route path="stats/:id/" element={<Stats />} />
                <Route path="pools/" element={<Pools />} />
                <Route path="pool-overview/:poolID" element={<PoolOverview />} />
                <Route path="unilevels/:id/" element={<UniLevel />} />
                <Route path="partners/:id/" element={<Partners />} />
            </Routes>
          </div>
        </div>
      </div>
    );
  }
}

export default withWrapper(DashboardLayout);
