import axios from "axios";
var data = (eventName) => JSON.stringify({
   "query": `query MyQuery {\n  tron {\n    smartContractEvents(\n      smartContractAddress: {is: \"TJpqgh8tLA1iFkG8xTSEMrBL1uL1zKbMLg\"}\n      options: {desc: \"block.height\", limit: 10000}\n      any: {smartContractEvent: {is: \"${eventName}\"}}\n    ) {\n      txHash\n      block {\n        height\n      }\n      smartContractEvent(smartContractEvent: {is: \"${eventName}\"}) {\n        name\n      }\n      arguments {\n        argument\n        value\n      }\n    }\n  }\n}\n`,
   "variables": "{}"
});

var config = (myData) => {
    return {
        method: 'post',
        url: 'https://graphql.bitquery.io',
        headers: { 
            'Content-Type': 'application/json', 
            'X-API-KEY': 'BQYMrtLymUNg4kCG1nAfQJk2lsBaU65z'
        },
        data : data(myData)
    }
}

const mapValues = ( mapData=[] ) => {
    mapData.forEach((ele) => {
        ele.arguments.forEach((argS) => {
            ele[argS.argument] = argS.value
        });
    })
    return mapData
}

export const fetchEvent = async (eventName) => {
    return new Promise((res, rej) => {
        axios(config(eventName))
        .then(function (response) {
            // console.log(response.data.data["tron"]["smartContractEvents"].length);
            res(mapValues(response.data.data["tron"]["smartContractEvents"]))
        })
        .catch(function (error) {
            console.log(error);
        });
    })
}
