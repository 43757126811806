import TronWeb from 'tronweb';
import { delay } from './activities';

const tronMainnetApi = "b00aa4b9-84b0-40b6-865f-d527537f1ba5";
const privateKeyDummyWallet = 'a46af023cf1e954be87e5925d2a1e1c8b3a41b92b36cfabcef123085f942abc2';

const getDefaultRPC = () => {
    return new Promise((resolve) => {

        try {
            resolve(
                // new TronWeb({
                //     fullHost: "https://nile.trongrid.io",
                //     // fullHost: "https://api.shasta.trongrid.io",
                //     headers: { "TRON-PRO-API-KEY": tronMainnetApi },
                //     privateKey: privateKeyDummyWallet,
                //   })
                new TronWeb(
                    "https://api.trongrid.io",
                    "https://api.trongrid.io",
                    "https://api.trongrid.io",
                    privateKeyDummyWallet
                )
            );
        } catch(err) {
            // alert("error"+JSON.stringify(err))
        }
    })
}

const getRPC = () => {
    return new Promise((resolve) => {
        resolve(
            window.tronWeb ? window.tronWeb :
            new TronWeb({
                fullHost: "https://api.trongrid.io",
                eventServer: 'https://api.tronex.io',
                // fullHost: "https://api.shasta.trongrid.io",
                headers: { "TRON-PRO-API-KEY": tronMainnetApi },
                privateKey: privateKeyDummyWallet,
            })
            // new TronWeb(
            //     "https://api.trongrid.io",
            //     "https://api.trongrid.io",
            //     "https://api.trongrid.io",
            //     privateKeyDummyWallet,
            //     {headers: { "TRON-PRO-API-KEY": tronMainnetApi }}
            // )
        );
    })
}

const getWallet = (forFetching = false) => {
    try {
        return new Promise(async (res) => {
            await delay(500)
            let tronWeb;
            if ( forFetching ) {
                tronWeb = await getRPC()
                res(tronWeb);
                return
            }
            if(window.tronWeb) {
                await window.tronLink.request({method: 'tron_requestAccounts'});
                tronWeb = window.tronWeb;
            } else {
                tronWeb = await getDefaultRPC()
            }
            res(tronWeb);
        })
    } catch(err) {
        // alert(err)
    }
    
}

export const getConnected = () => {
    return new Promise(async (res) => {
        try{
            const tronWebInst = await getWallet()
            res(tronWebInst.defaultAddress.base58);
        } catch(err){
            res(null)
        }
    })
}

export default getWallet;