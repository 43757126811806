import React, { Component } from "react";
import Header from "./header";
import SidePanel from "./sidePanel";
import Dashboard from "./dashboard";
import Matrix from "./matrix";
import { ethers } from 'ethers';
import getWallet from '../../services/wallet';
import withWrapper from '../../helpers/withWrapper';
import getContract from "../../services/contract";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class UserInfo extends Component {
  constructor(props) {
    super(props);
    console.log('userInfo instantiated');
    if(this.props.view == 'dashboard') {
      this.state.view = 'dashboard';
    } else if(this.props.view == 'matrix') {
      this.state.view = 'matrix';
      const level = this.props.router.params.level
      if(level > 0) this.state.level = level;
      else this.state.level = 1;
    }
    if(this.props.router.params.id > 0) {
      this.state.isViewMode = false;
      this.state.userId = this.props.router.params.id;
    }
    else {
      this.state.userId = 1;
      this.state.isViewMode = false;
    }
  }
  state = {
    view: 'dashboard',
    isViewMode: false,
    signer: false
  };

  notify = (message) => toast(message);

  connectWallet = async () => {
    const tronWeb = await getWallet();
    if(tronWeb && tronWeb.defaultAddress.base58 !== false) {
      this.setState({ signer: true });
      return true;
    }
    return false;
  }

  handleWalletConnected = async (contract) => {
    this.setState({ signer: contract.signer });
  }

  handleConnectWallet = async () => {
    const isConnected = await this.connectWallet();
    // if(isConnected)
    //   this.notify("Wallet Connected");
  }

  handleDashboardClick = () => {
    this.props.router.navigate('/dashboard/' + this.state.userId);
    const view = 'dashboard';
    this.setState({ view });
  }

  handleSlotClick = (level) => {
    this.props.router.navigate('/dashboard/' + this.state.userId + '/' + level);
    this.setState({ level, view: 'matrix' });
  }

  handleDisconnect = () => {
    this.props.router.navigate('/login');
  }
 
  render() {
    return (
      <div id="__next1">
        <div className="relative flex bg-main-bg items-center justify-center min-h-screen min-w-full overflow-hidden">
          <Header onDisconnect={this.handleDisconnect} onConnectWallet={this.handleConnectWallet} signer={this.state.signer} />
          <div className="flex w-full h-full justify-center max-w-desktop-full">
            <SidePanel onDashboardClick={this.handleDashboardClick} />

            { this.state.view == 'dashboard' ? 
            <Dashboard 
              onSlotClick={this.handleSlotClick} isViewMode={this.state.isViewMode}
              userId={this.state.userId} onWalletConnected={this.handleConnectWallet} />
              : <Matrix router={this.props.router} level={this.state.level} 
                userId={this.state.userId} idsOnLevel={this.state.idsOnLevel} />} 
          </div>
        </div>
      </div>
    );
  }
}

export default withWrapper(UserInfo);
