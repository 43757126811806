import React, { Component } from 'react';
import withRouter from '../../helpers/withWrapper'
import { getStatsData } from '../../services/partners'
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Slot from "./tree/slot";
import levelPrices from "../../services/levelPrice";
import PreviousPools from "./PreviousPools";
import getWallet from "../../services/wallet";
import {livePool} from "../../services/getPools";
import moment from "moment";

class Pools extends Component {
    state = {
        data: {},
        userAddress: '',
        currentPool: {
            collected: "0",
            endAt: 0,
            totalShares: "-",
            myShare: "-",
            perShare: "-",
            endFormat: "",
            address: ""
        }
    }

    constructor(props) {
        super(props);
        this.state.userId = props.router.params.id;
    }
    async componentDidMount() {
        try {
            // toast.info(`initialized`)
            const address = await getWallet()
            // toast.info("wallet init")
            this.setState({userAddress: address.defaultAddress.base58})
            const getLivePool = await livePool(address.defaultAddress.base58)
            // toast.info(`live loaded`)

            const startDate = Date.now()
            const endDate = parseFloat(getLivePool.endAt) * 1000

            // console.log(getLivePool.endAt * 1000)
            // console.log(startDate)
            const diff = endDate - startDate
            const formatDate = moment.duration(diff)
            if ( formatDate > 0 ) {
                // console.log(formatDate.asDays())
                if ( formatDate.months() > 0 ) {
                    getLivePool['endFormat'] = `${Math.round(formatDate.asDays())}d ${formatDate.hours()}h ${formatDate.minutes()}m`
                }
                else {
                    getLivePool['endFormat'] = `${formatDate.days()}d ${formatDate.hours()}h ${formatDate.minutes()}m`
                }
            }

            this.setState({currentPool: getLivePool})
        } catch(e) {
            toast.info(e.toString())
        }

        // const promise = getStatsData(this.state.userId);
        // const result = await toast.promise(promise, {
        //     pending: "Fetching data please wait...",
        //     success: "Data loaded",
        //     error: "Error fetching data",
        // });
        // this.setState({data: result});
    }
    render() {


        return (
            <div className="flex flex-shrink w-full flex-col items-center min-h-screen bg-main pt-20 sm:pt-14">
                {/* <ToastContainer position="bottom-right" /> */}
                <div className="flex relative flex-1 py-2 pt-8.5 flex-col justify-between w-full px-10 sm:px-0 sm:pt-7.5">
                    {/* Top Panel, Matrix levels and all activies inside below div */}
                    <div className="flex flex-col w-full space-y-10 sm:space-y-5">

                        {/* Top panel of Dashboard mobile view inside below div */}
                        <div
                            className="flex w-full items-start justify-between space-x-10 lg:space-x-2.5 sm:space-x-0 sm:space-y-5 sm:flex-col sm:px-5">
                            <div className="flex items-start flex-shrink-0 sm:flex-shrink">
                                <div
                                    className="flex-shrink-0 relative w-30 h-30 rounded-full cursor-pointer sm:w-15 sm:h-15">
                                    <div className="profile-logo-box w-26 h-16 sm:h-14 sm:w-14 false rounded-full p-2">
                                        <img
                                            src="/images/logo.png" />
                                    </div>

                                </div>
                                <div className="flex flex-col items-start ml-6 h-full justify-center my-auto">
                                    <div className="flex justify-center items-center mb-1 sm:mb-2.5"><span
                                        className="font-bold text-3xl mr-2.5 sm:text-xl cursor-pointer showContractId" style={{color: "#ffc10c"}}>
                                                Global Founder's Pool </span></div>
                                    <div className="flex flex-col items-start">
                                        <div className="flex items-center mb-1">
                                                <span className="text-white font-bold mr-2.5 text-base sm:text-sm showWalletAddress">
                                                Your Wallet: {(this.state.userAddress !== '') ? (this.state.userAddress.slice(0, 4) + '...' + this.state.userAddress.slice(this.state.userAddress.length - 4, this.state.userAddress.length))
                                                    : ''}
                                                    </span>
                                            </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="relative flex flex-grow max-w-500px w-full h-full flex-col p-5 justify-between bg-black-light rounded sm:max-w-full theme-card-bg">
                                <div className="flex items-center"><span
                                    className="text-base sm:text-sm">Current Pool Ends In</span></div>
                                <div className="md:flex flex-wrap justify-between false">
                                        <span
                                            className="text-blue-600 text-lg font-bold lg:mb-2.5 sm:text-xl break-all">
                                            {
                                                this.state.currentPool.endFormat
                                            }
                                        </span>
                                    <div
                                        className="flex justify-between items-center text-left border-t border-white-100 w-full mt-2.5 pt-2.5">
                                        <div className="flex flex-wrap mr-1.5">
                                            <div className="mr-1.5">Not updated your pool shares? </div>
                                        </div>
                                        <button
                                            className="flex justify-center text-black items-center text-center text-base font-bold rounded-mini sm:text-sm outline-none py-0 px-2.5 bg-white rounded !leading-30px hover:bg-blue-500 active:bg-active-main-blue w-max flex-shrink-0"
                                            onClick={() => {
                                                if ( this.state.currentPool.address !== "" ) {
                                                    this.props.router.navigate("/pool-overview/" + this.state.currentPool.address);
                                                }
                                            }}
                                        >
                                            Update Your Shares!
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Top panel of Dashboard mobile view inside above div */}

                        {/* Top panel Partners, Team, Profit ration, Profits View inside below div */}
                        <div className="flex w-full mt-15 sm:mt-5 sm:px-5">
                            <div className="flex w-full flex-wrap lg:flex-col">
                                <div
                                    className="flex mr-10 space-x-10 lg:mr-0 lg:mt-10 sm:mt-5 sm:space-x-5 lg:order-2 sm:overflow-auto sm:w-full">
                                    <div
                                        className="lg:flex-grow flex flex-col p-5 bg-black-light rounded desktop-infoblock-base sm:mobile-infoblock-base theme-card-bg">
                                        <div className="flex items-center"><span
                                            className="text-base sm:text-sm sm:whitespace-nowrap">Total Shares</span>
                                        </div><span
                                        className="text-2xl font-bold sm:text-xl mt-5 sm:mt-2.5">
                                        {
                                            this.state.currentPool.totalShares
                                        }
                                    </span>

                                    </div>
                                    <div
                                        className="lg:flex-grow flex flex-col p-5 bg-black-light rounded desktop-infoblock-base sm:mobile-infoblock-base theme-card-bg">
                                        <div className="flex items-center"><span
                                            className="text-base sm:text-sm sm:whitespace-nowrap">Your Shares</span>
                                        </div><span className="text-2xl font-bold sm:text-xl mt-5 sm:mt-2.5"
                                                    id="yourTeamTreeCount">
                                        {
                                            this.state.currentPool.myShare
                                        }
                                    </span>

                                    </div>
                                    <div
                                        className="lg:flex-grow sm:max-w-full flex flex-col p-5 bg-black-light rounded desktop-infoblock-base sm:mobile-infoblock-base theme-card-bg">
                                        <div className="flex items-center"><span
                                            className="text-base sm:text-sm sm:whitespace-nowrap">Per Share</span>
                                        </div>
                                        <span
                                            className="text-2xl font-bold sm:text-xl mt-5 sm:mt-2.5"
                                        >
                                            {
                                                this.state.currentPool.perShare
                                            } TRX
                                        </span>
                                    </div>
                                </div>
                                <div
                                    className="flex flex-1 max-h-140px sm:max-w-full overflow-hidden w-full relative justify-start items-end bg-black-light rounded lg:max-w-full desktop-infoblock-chart-base theme-card-bg">
                                    <div
                                        className="flex-shrink-0 flex flex-col p-5 rounded desktop-infoblock-base sm:mobile-infoblock-base">
                                        <div className="flex items-center"><span
                                            className="text-base sm:text-sm sm:whitespace-nowrap">Global Founders Shares Collected</span>
                                        </div><span className="text-2xl font-bold sm:text-xl mt-5 sm:mt-2.5">
                                                {
                                                    this.state.currentPool.collected
                                                } TRX</span>
                                            {/* {
                                                this.state.currentPool.address !== "" && 
                                                <a
                                                    target={"_blank"}
                                                    className="flex justify-center text-black items-center text-center text-base font-bold rounded-mini sm:text-sm outline-none py-0 px-2.5 bg-white rounded !leading-30px hover:bg-blue-500 active:bg-active-main-blue w-max flex-shrink-0"
                                                    href={`https://tronscan.org/#/contract/${this.state.currentPool.address}`}
                                                >
                                                    Verify Balance
                                                </a>
                                            } */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Top panel Partners, Team, Profit ration, Profits View inside above div */}
                    </div>
                    {/* Top Panel, Matrix levels and all activies inside above div */}
                    {/* Previous Pools Shares */}
                    <PreviousPools />
                    {/*End Previous Pools Shares*/}

                    <footer
                        className="flex lg:flex-col items-center mt-auto justify-between lg:justify-start lg:items-start py-10 w-full lg:p-5 lg:pb-9 pb-0 lg:pb-0 z-10">
                        <div className="hidden lg:block mb-7.5">
                            <div className="flex space-x-5 w-full"><a
                                className="w-6 h-6 sm:w-7 sm:h-7 flex justify-center items-center rounded-full bg-white-100 hover:bg-white-300"
                                target="_blank" href="https://t.me/oxcashnetwork"><svg
                                className="w-3 h-3 sm:w-4 sm:h-4" viewBox="0 0 14 12" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path clipRule="evenodd"
                                      d="M11.915.776.841 5.168a.4.4 0 0 0 .03.755l2.79.864 1.133 3.503a.4.4 0 0 0 .653.17l1.64-1.523 3.11 2.29a.4.4 0 0 0 .627-.231l2.166-9.294a.8.8 0 0 0-1.075-.926Zm-6.483 6.74-.346 2.072-.8-2.901 7.816-5.1-6.67 5.929Z"
                                      fill="#fff"></path>
                            </svg></a>

                            </div>
                        </div><span className="text-white-500 text-xs font-normal lg:mb-2.5">© 2022 All Rights
                                Reserved</span>
                    </footer>
                </div>
            </div>
        );
    }
}

export default withRouter(Pools);