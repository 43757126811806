import React, { Component } from "react";
import { getDate, getPartnersData } from "../../services/partners";
import { ToastContainer, toast } from "react-toastify";
import withRouter from '../../helpers/withWrapper';
import "react-toastify/dist/ReactToastify.css";
import getWallet from "../../services/wallet";
import getContract from "../../services/contract";
import { ethers } from "ethers";
import { sleep } from "../../services/activities";

class Partners extends Component {
  state = {
    data: [],
    actualLength: 0
  };

  constructor(props) {
    super(props);

    this.state.userId = props.router.params.id;
  }

  async componentDidMount() {
    this.initData()
  }

  async initData() {
    const promise = getPartnersData(this.state.userId);
    const activities = await toast.promise(promise, {
      pending: "Fetching data please wait...",
      success: "Data loaded",
      error: "Error fetching data",
    });

    this.setState({actualLength: activities.length})

    let tronWeb = await getWallet();
    const contract = await getContract();
    let data = [];

    try {
      for(let i = 0; i < activities.length; i++) {
        // toast.info(contract.toString())
        const userAddress = await contract.getAddressById(activities[i].newUserId).call();
        // toast.info(userAddress.toString())/

        const userInfo = await contract.userInfos(userAddress.toString()).call();
        const date = getDate(activities[i].time);

        let obj = {};
        obj["joinDate"] = date.toDateString();
        obj["userId"] = activities[i].newUserId;
        
        obj["address"] = tronWeb.address.fromHex(userAddress.toString());

        const partnerIncome = ethers.BigNumber.from(userInfo["totalIncome"]).toNumber();

        if(partnerIncome > 0) obj["partnerIncome"] = partnerIncome / 1000000;
        else obj["partnerIncome"] = 0;

        data.push(obj);
        this.setState({ data: data });
        await sleep(1500)
      }
    } catch(e){
      toast.error(e.toString())
      // await this.initData()
    }
    // console.log(promise)
    // if ( promise.retry ) {
    //   setTimeout(() => {
    //     this.initData()
    //   }, 10_000)
    //   return;
    // }
    // if ( promise.length === 0 ) {
    //   this.initData()
    //   return;
    // }
  }

  async componentWillUnmount() {
    toast.dismiss();
  }
  notify = (message) => toast(message);

  handleCopy = (address) => {
    navigator.clipboard.writeText(address);
    this.notify("Copied to clipboard");
  }

  render() {
    return (
      <>
        <div className="flex flex-shrink w-full flex-col items-center min-h-screen bg-main pt-20 sm:pt-14">
          <div className="flex relative flex-1 py-2 pt-8.5 flex-col justify-between w-full px-10 sm:px-0 sm:pt-7.5">
            <main className="flex flex-1 w-full">
              <div className="flex flex-col w-full space-y-10 sm:space-y-7.5">
                <div className="flex flex-col">
                  <div className="flex mb-7.5 sm:px-5 sm:justify-between">
                    <span className="text-3xl text-white font-medium sm:text-2xl">
                      Partners
                    </span>
                  </div>
                  <div className="p-5">
                  {
                    this.state.actualLength !== 0 &&
                    <span className="text-sm text-white">
                      Loaded - {this.state.data.length} / {this.state.actualLength} ( *Note: This data is fetched from last 10,000 event transactions )
                    </span>
                  }
                  </div>
                  <div className="flex flex-col mb-5 w-full sm:px-5">
                    <div className="flex-1 z-10 flex flex-col w-full flex-col bg-black-light rounded overflow-hidden h-full min-h-90 sm:max-h-3/4">
                      <div className="overflow-auto">
                        <table className="min-w-max w-full table-auto border-white-100">
                          <thead>
                            <tr className="text-white-500 text-xs font-normal border-b border-white-100">
                              <th className="p-6 xl:p-4 lg:p-6 sm:p-3 text-left">
                                Date
                              </th>
                              <th className="p-6 xl:p-4 lg:p-6 sm:p-3 text-left">
                                Address
                              </th>
                              <th className="p-6 xl:p-4 lg:p-6 sm:p-3 text-left">
                                ID
                              </th>
                              {/* <th className="p-6 xl:p-4 lg:p-6 sm:p-3 text-center">Referrar
                                                                Earning</th> */}
                              <th className="p-6 xl:p-4 lg:p-6 sm:p-3 text-left">
                                Profit
                              </th>
                            </tr>
                          </thead>
                          <tbody className="text-gray-600 text-sm font-light">
                            {this.state.data.map((d, i) => {
                              return (
                                <tr
                                  key={i}
                                  className="border-b border-white-100 whitespace-nowrap last:border-b-0"
                                >
                                  <td className="p-6 xl:p-4 lg:p-6 sm:p-3 whitespace-nowrap text-left text-white-500 font-medium text-xs">
                                    {d.joinDate}
                                  </td>
                                  <td className="p-6 xl:p-4 lg:p-6 sm:p-3 whitespace-nowrap text-left">
                                    <div className="flex items-center space-x-2.5">
                                      <span className="text-white font-medium text-xs">
                                        {d.address}
                                      </span>
                                      <button onClick={() => this.handleCopy(d.address)}>
                                        <svg
                                          className="h-18px w-18px"
                                          viewBox="0 0 20 20"
                                          fill="#fff"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path d="M15 6.667H8.332c-.92 0-1.667.746-1.667 1.666V15c0 .92.746 1.667 1.667 1.667h6.666c.92 0 1.667-.747 1.667-1.667V8.333c0-.92-.746-1.666-1.667-1.666Z"></path>
                                          <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M3.469 3.468A2.167 2.167 0 0 1 5 2.833h6.666A2.167 2.167 0 0 1 13.834 5v1.667a.5.5 0 0 1-1 0V5a1.167 1.167 0 0 0-1.167-1.167H5.001A1.167 1.167 0 0 0 3.834 5v6.667a1.167 1.167 0 0 0 1.167 1.166h1.666a.5.5 0 1 1 0 1H5.001a2.167 2.167 0 0 1-2.167-2.166V5c0-.575.228-1.126.635-1.532Z"
                                          ></path>
                                        </svg>
                                      </button>
                                    </div>
                                  </td>
                                  <td className="p-6 xl:p-4 lg:p-6 sm:p-3 whitespace-nowrap text-left">
                                    <a
                                      className="flex items-center justify-center px-2.5 leading-30px bg-blue-100 hover:bg-red-600 text-red-600 hover:text-white rounded w-max text-sm"
                                    >
                                      ID
                                      {d.userId}
                                    </a>
                                  </td>
                                  {/* <td className="p-6 xl:p-4 lg:p-6 sm:p-3 whitespace-nowrap text-left text-white font-medium text-xs">
                                                                    TRX</td> */}
                                  <td className="p-6 xl:p-4 lg:p-6 sm:p-3 whitespace-nowrap text-left text-white font-medium text-xs">
                                    {d.partnerIncome} TRX
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </main>
            <footer className="flex lg:flex-col items-center mt-auto justify-between lg:justify-start lg:items-start py-10 w-full lg:p-5 lg:pb-9 pb-0 lg:pb-0 z-10">
              <div className="hidden lg:block mb-7.5">
                <div className="flex space-x-5 w-full">
                  <a
                    className="w-6 h-6 sm:w-7 sm:h-7 flex justify-center items-center rounded-full bg-white-100 hover:bg-white-300"
                    target="_blank"
                    href="https://t.me/oxcashnetwork"
                  >
                    <svg
                      className="w-3 h-3 sm:w-4 sm:h-4"
                      viewBox="0 0 14 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M11.915.776.841 5.168a.4.4 0 0 0 .03.755l2.79.864 1.133 3.503a.4.4 0 0 0 .653.17l1.64-1.523 3.11 2.29a.4.4 0 0 0 .627-.231l2.166-9.294a.8.8 0 0 0-1.075-.926Zm-6.483 6.74-.346 2.072-.8-2.901 7.816-5.1-6.67 5.929Z"
                        fill="#fff"
                      ></path>
                    </svg>
                  </a>
                </div>
              </div>
              <span className="text-white-500 text-xs font-normal lg:mb-2.5">
                © 2022 All Rights Reserved
              </span>
            </footer>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(Partners);
