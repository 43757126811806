import React, { Component } from 'react';
import withRouter from '../../../helpers/withWrapper'
import { getStatsData } from '../../../services/partners'
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {PoolItem} from "./PoolItem";
import {getPools} from "../../../services/getPools";
class PreviousPools extends Component {
    state = {
        data: [],
    }

    constructor(props) {
        super(props);
        this.state.userId = props.router.params.id;
    }
    async componentDidMount() {
        const result = await getPools();
        this.setState({data: result});
    }
    render() {
        return (
            <div className="flex flex-shrink w-full flex-col items-center min-h-screen bg-main pt-20 sm:pt-14">
                <div className="flex relative flex-1 py-2 pt-8.5 flex-col justify-between w-full px-10 sm:px-0 sm:pt-7.5">
                    <main className="flex flex-1 w-full">
                        <div className="flex flex-col w-full space-y-10 sm:space-y-7.5">
                            <div className="flex flex-col">
                                <div className="flex mb-7.5 sm:px-5 sm:justify-between">
                                    <span className="text-3xl text-white font-medium sm:text-2xl">Pool History</span>
                                </div>
                                <div className="flex flex-col mb-5 w-full sm:px-5">
                                    <div className="flex-1 sm:rounded-none z-10 flex flex-col w-full flex-col bg-black-light rounded overflow-hidden h-full min-h-90 sm:max-h-3/4">
                                        <div className="overflow-auto">
                                            <table className="min-w-max w-full table-auto border-white-100">
                                                <thead>
                                                <tr className="text-white-500 text-xs font-normal border-b border-white-100">
                                                    <th className="p-4 text-left font-normal sm:p-3 "></th>
                                                    <th className="p-4 text-left font-normal sm:p-3">Started</th>
                                                    <th className="p-4 text-left font-normal sm:p-3">Ended</th>
                                                    {/* <th className="p-4 text-left font-normal sm:p-3">Hash</th> */}
                                                    <th className="p-4 text-left font-normal sm:p-3">Total Collected</th>
                                                    <th className="p-4 text-left font-normal sm:p-3">Per Share</th>
                                                    {/* <th className="p-4 text-left font-normal sm:p-3">Claimed</th> */}
                                                    <th className="p-4 text-left font-normal sm:p-3">Actions</th>
                                                </tr>
                                                </thead>
                                                <tbody className="text-gray-600 text-sm font-light">
                                                {this.state.data.map((d, idx) => {
                                                    return (
                                                        <PoolItem idx={idx} d={d} />
                                                    )
                                                })}
                                                </tbody>
                                            </table>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        );
    }
}

export default withRouter(PreviousPools);