import React, { Component, useState } from 'react';

class Header extends Component {
    
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        this.props.onConnectWallet();
    }
    state = {  }

    toggleSideBar = () => {
        if(this.props.sideBarToggle){
            this.props.sideBarToggle()
        }
    }

    render() { 
        return (
            <div className="fixed top-0 left-1/2 -translate-x-1/2 flex justify-center w-full px-10 py-2.5 lg:p-0 lg:max-h-screen bg-gray-900 z-999999">
                <div className="flex justify-between items-center rounded-mini max-w-desktop-preview-bar w-full bg-gray-900 px-5 py-2 shadow-preview-bar lg:pl-10 sm:pl-5 lg:py-2.5 lg:rounded-none lg:rounded-b-mini lg:pr-0 false">
                    <div className="flex w-full overflow-hidden items-center justify-between space-x-2.5 lg:items-start ">
                        <div className="w-full flex justify-start items-center space-x-5 lg:flex-col lg:h-full lg:items-start lg:space-x-0 lg:space-y-7.5">
                            <div className="flex items-center  false lg:w-full lg:pr-10 sm:pr-5">
                                <a href="https://pro.ox.cash/" className="header-link" style={{marginRight: '95px'}} >
                                    <img src="/images/logo-text.png" className="light w-20" style={{width: "180px !important"}} alt="" />
                                    <img src="/images/logo-text.png" className="dark w-20" alt="" style={{width: "180px !important"}} />
                                </a>
                            </div>
                        </div>
                        { (!this.props.signer) && <button className="flex justify-center items-center text-center text-base font-bold text-white rounded-mini sm:text-sm outline-none px-5 py-3 bg-main-bg hover:bg-black-500 whitespace-nowrap lg:hidden"
                            onClick={this.props.onConnectWallet} id="connectWallet" >Connect wallet</button> }
                    </div>
                    <div className="flex lg:justify-end lg:ml-auto lg:pr-10 sm:pr-5">
                        <button onClick={this.props.onDisconnect} className="flex justify-center items-center text-center text-base font-bold text-white rounded-mini sm:text-sm outline-none px-5 py-3 bg-white-100 hover:bg-white-300 rounded-full lg:hidden w-10 h-10 !p-0 ml-5"><svg
                                className="w-6 h-6 cursor-pointer" viewBox="0 0 24 24" fill="none" stroke="#fff"
                                xmlns="http://www.w3.org/2000/svg">
                                <path d="M18 6 6 18M6 6l12 12" strokeLinecap="round" strokeLinejoin="round"></path>
                            </svg></button>
                        <div className="">
                            <button onClick={this.toggleSideBar} className="mobile-menu-button flex justify-center items-center text-center text-base font-bold text-white rounded-mini sm:text-sm outline-none px-5 py-3 bg-white-100 hover:bg-white-300 flex-col rounded-full w-10 h-10 !p-0 hidden lg:flex"><span
                                    className="w-4 border-t border-white mb-2"></span><span
                                    className="w-4 border-t border-white"></span></button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
 
export default Header;