import { ethers } from 'ethers';
import { getPartnersActivities, getDirectPayouts, delay, sleep } from './activities';
import getContract from './contract';
import getWallet from './wallet'

export const getPartnersData = async (userId) => {

    return new Promise(async (resolve, reject) => {
        try {
            const activities = await getPartnersActivities(userId);

            
            resolve(activities)
        }catch(er) {
            console.log(`Error:`, er)
            if ( er.statusCode === 403 ) {
                resolve({retry: true})
            }
            resolve([])
        }
    })
}

export const getStatsData = async (userId) => {
    const activities = await getDirectPayouts(userId);
    let data = [];
    for(let i = 0; i < activities.length; i++) {
        const date = getDate(activities[i].timeNow);
        let obj = {};
        obj["joinDate"] = date.toDateString();
        obj["from"] = activities[i].from;
        obj["to"] = activities[i].to;
        obj["level"] = activities[i].level;
        obj["amount"] = +activities[i].amount / 1000000;

        data.push(obj);
    }
    return data;
}

export function getDate(x) {
    const myDate = new Date(x * 1000);
    return myDate;
 }