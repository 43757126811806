import React from "react";
import {useNavigate} from "react-router-dom";

export const PoolItem = ({d, idx}) => {
    const navigate = useNavigate();

    const start = new Date( d.startedAt * 1000 )
    const end = new Date( d.endAt * 1000 )

    return (
        <tr key={idx} className="border-b border-white-100 last:border-none">
            <td className="p-4 text-left whitespace-nowrap sm:p-3">
                <div className="flex w-full">
                    <div className="relative flex items-center justify-center rounded-full bg-white-100 w-11 h-11 sm:w-7.5 sm:h-7.5">
                        <svg className="text-white h-18px w-18px " viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10 8.667A3.333 3.333 0 1 0 10 2a3.333 3.333 0 0 0 0 6.667Z" fill="#fff" stroke="#fff" strokeLinecap="round" strokeLinejoin="round"></path>
                            <path d="M5 17v-1.667A3.333 3.333 0 0 1 8.333 12h3.334A3.333 3.333 0 0 1 15 15.333V17" fill="#fff"></path>
                            <path d="M5 17v-1.667A3.333 3.333 0 0 1 8.333 12h3.334A3.333 3.333 0 0 1 15 15.333V17H5Z" stroke="#fff" strokeLinecap="round" strokeLinejoin="round"></path>
                        </svg><span className="absolute top-0 right-0 text-mini leading-3 text-white"></span>
                    </div>
                </div>
            </td>
            <td className=" p-4 text-left whitespace-nowrap sm:p-3">
                <div className="text-white-500 text-sm ">
                    { start.toLocaleString() }
                </div>
            </td>
            <td className="p-4 text-left whitespace-nowrap sm:p-3">
                <div className="text-white text-sm ">
                    { end.toLocaleString() }
                </div>
            </td>
            <td className="p-4 text-left whitespace-nowrap sm:p-3">
                <div className="text-white text-sm ">
                    {d.collected / 1e6} TRX
                </div>
            </td>
            <td className="p-4 text-left whitespace-nowrap sm:p-3">
                <div className="text-white text-sm ">
                    {d.perShare} TRX
                </div>
            </td>
            {/* <td className="p-4 text-left whitespace-nowrap sm:p-3">
                <div className="text-white text-sm ">
                    {d.claimed} TRX
                </div>
            </td> */}
            <td className="p-4 text-left whitespace-nowrap sm:p-3">
                <div className="text-white text-sm ">
                    <button
                        className={`flex justify-center items-center text-center text-base text-black rounded-mini sm:text-sm outline-none py-0 px-2.5 bg-${!d.isActive ? "red-300": "green-400"} rounded !leading-30px hover:bg-blue-500 active:bg-active-main-blue w-max flex-shrink-0`}
                        onClick={() => {
                            navigate(`/pool-overview/${d.address}`)
                        }}
                    >
                        {(!d.isActive && ((d.endAt + d.gracePeriod )* 1000 ) > Date.now()) ? "Claim": "View"}
                    </button>
                </div>
            </td>
        </tr>
    )
}