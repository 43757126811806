import React, { Component } from 'react';
import withWrapper from '../helpers/withWrapper'
import getContract from '../services/contract';
import getWallet from '../services/wallet';
import { TransactionService } from '../services/transactionService'
import './registerPage.css'
import queryString from 'query-string';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


class Register extends Component {
  state = {
    referrerId: 1,
    userAddress: null,
    isConnected: false,
    loading: false,
  };
  constructor(props) {
    super(props);
    console.log(this.props.router);
    const searchParams = this.props.router.location.search;
    if (searchParams) {
      const { referral } = queryString.parse(searchParams);
      if (referral) {
        this.state.referrerId = referral;
      }
    } else {
      this.state.referrerId = 1;
    }
  }

  notify = (message) => toast(message);

  async componentDidMount() {
    await this.connectWallet();
  }

  handleRegister = async () => {
    if (!this.state.isConnected) {
      alert("Connect wallet");
      return;
    }
    try {
      this.setState({loading: true})
      var transactionService = new TransactionService(this.state.userAddress);
      // alert(this.state.referrerId)
      const registrationPromise = transactionService.register(
        this.state.referrerId,
        toast
      );
      const newId = await toast.promise(registrationPromise, {
        pending: "Registration in process",
        success: "Registration Completed! 👌",
        error: "Registration failed ",
      });
      this.setState({loading: false})
      // const newId = await transactionService.register(this.state.referrerId);
      if (newId > 0) {
        await this.wait();
        this.props.router.navigate("/dashboard/" + newId);
        this.setState({loading: true})
      } 
    } catch (error) {
      this.setState({loading: false})
      alert(error.message);
    }
  };

  wait = () => {
    return new Promise((res) => {
      setTimeout(() => {
        res(1);
      }, 4000);
    });
  };

  connectWallet = async () => {
    const tronWeb = await getWallet();
    if (tronWeb && tronWeb.defaultAddress != false) {
      const browserAddress = tronWeb.defaultAddress.base58;
      if ( browserAddress.toLowerCase() !== "TXrwicLhxinpPP3xLhNqyKZa3nmV3zfNwK".toLowerCase() ) {
        this.setState({ userAddress: browserAddress, isConnected: true });
        this.notify("Wallet Connected");
      }
    }
  };

  render() {
    return (
      <div id="__next1">
        <ToastContainer />
        <div className="flex relative bg-main-bg flex-col items-center justify-center w-full min-h-screen text-white-500 px-10 sm:px-0 overflow-hidden pt-16">
          <header className="fixed top-0 w-full pb-2.5 pt-2.5 px-10 z-30 bg-gray-900 sm:px-5 lg:border-b lg:border-white-100">
            <nav className="z-10 w-full max-w-desktop-preview-bar m-auto header-border-b">
              <div className="flex items-center justify-between">
                <a href="/" className="header-link">
                  <img
                    src="/images/logo-text.png"
                    style={{width: "180px !important"}}
                    className="light w-20"
                    width={30}
                    alt=""
                  />
                  <img
                    src="/images/logo-text.png"
                    style={{width: "180px !important"}}
                    className="dark w-20"
                    width={30}
                    alt=""
                  />
                </a>
                <div className="flex justify-end items-center ml-auto">
                  {!this.state.isConnected ? (
                    <button
                      onClick={this.connectWallet}
                      className="flex justify-center items-center text-center text-base font-bold text-white rounded-mini sm:text-sm outline-none px-5 py-2.5 bg-black-light hover:bg-line-gray active:bg-active-gray text-white font-normal rounded items-center"
                      id="connectWallet"
                    >
                      Connect wallet
                    </button>
                  ) : (
                    <button className="flex justify-center items-center text-center text-base font-bold text-white rounded-mini sm:text-sm outline-none px-5 py-2.5 bg-black-light hover:bg-line-gray active:bg-active-gray text-white font-normal rounded items-center">
                      {this.state.userAddress?.substr(0, 4) + "..." + this.state.userAddress?.substr(-4)}
                    </button>
                  )}
                </div>
              </div>
            </nav>
            <div className="hidden justify-start items-start bg-main-bg pb-5 pt-7.5 transition duration-500 ease-in-out bg-main-bg absolute top-0 left-0 z-20 inset-0 h-screen w-screen z-999999 -translate-x-120% lg:flex flex-col lg:py-2.5">
              <div
                className="w-full flex flex-col flex-1"
                style={{ height: 757 }}
              >
                <div className="flex justify-between items-center w-full px-10 sm:px-5">
                  <a>
                    <svg
                      className="block mr-2.5"
                      xmlns="http://www.w3.org/2000/svg"
                      width="163"
                      height="30"
                      viewBox="0 0 679 136"
                    >
                      <image y="11" width="676" height="125" />
                      <image x="74" width="72" height="48" />
                    </svg>
                  </a>
                  <button className="flex justify-center items-center text-center text-base font-bold text-white rounded-mini sm:text-sm outline-none px-0 py-0 bg-black-light rounded-full w-10 h-10 hover:bg-line-gray active:bg-active-gray hidden lg:flex flex-col">
                    <span className="w-4 border-t border-white -mb-px rotate-45"></span>
                    <span className="w-4 border-t border-white -rotate-45"></span>
                  </button>
                </div>
                <div className="w-full h-full pl-10 pr-7.5 lg:pr-0 lg:pl-10 sm:pl-5 overflow-auto">
                  <div className="flex flex-1 flex-col h-full w-full overflow-y-auto space-y-2.5 lg:space-y-0">
                    <div className="flex flex-1 flex-col w-full h-full overflow-y-auto space-y-2.5 lg:space-y-0 lg:mb-5">
                      <a href="/dashboard">
                        <button className="relative w-full flex items-center px-2.5 py-2 rounded-xl cursor-pointer undefined hover:bg-black-light lg:hover:bg-transparent false lg:border-b lg:border-white-300 lg:rounded-none lg:pl-0 lg:pr-5 lg:py-5 lg:justify-between">
                          <div className="flex items-center text-left">
                            <svg
                              className="w-6 h-6 stroke-current text-white-500 lg:w-5 lg:h-5"
                              viewBox="0 0 24 24"
                              stroke="#fff"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M9 4H5a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1ZM19 4h-4a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1ZM9 14H5a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1v-4a1 1 0 0 0-1-1ZM19 14h-4a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1v-4a1 1 0 0 0-1-1Z"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              ></path>
                            </svg>
                            <span className="text-white-500 text-base  ml-5 false">
                              Dashboard
                            </span>
                          </div>
                        </button>
                      </a>
                      <a href="/partners">
                        <button className="relative w-full flex items-center px-2.5 py-2 rounded-xl cursor-pointer undefined hover:bg-black-light lg:hover:bg-transparent false lg:border-b lg:border-white-300 lg:rounded-none lg:pl-0 lg:pr-5 lg:py-5 lg:justify-between">
                          <div className="flex items-center text-left">
                            <svg
                              className="w-6 h-6 stroke-current text-white-500 lg:w-5 lg:h-5"
                              viewBox="0 0 16 16"
                              fill="none"
                              stroke="#fff"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M6 7.333A2.667 2.667 0 1 0 6 2a2.667 2.667 0 0 0 0 5.333ZM2 14v-1.333A2.667 2.667 0 0 1 4.667 10h2.666A2.667 2.667 0 0 1 10 12.667V14M10.667 2.086a2.667 2.667 0 0 1 0 5.167M14 14v-1.333a2.667 2.667 0 0 0-2-2.567"
                                strokeWidth="1.333"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              ></path>
                            </svg>
                            <span className="text-white-500 text-base  ml-5 false">
                              Partners
                            </span>
                          </div>
                        </button>
                      </a>
                      <a href="/stats">
                        <button className="relative w-full flex items-center px-2.5 py-2 rounded-xl cursor-pointer undefined hover:bg-black-light lg:hover:bg-transparent false lg:border-b lg:border-white-300 lg:rounded-none lg:pl-0 lg:pr-5 lg:py-5 lg:justify-between">
                          <div className="flex items-center text-left">
                            <svg
                              className="w-6 h-6 stroke-current text-white-500 lg:w-5 lg:h-5"
                              viewBox="0 0 24 24"
                              stroke="#fff"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M16 12h5m-9-9v5-5ZM8.929 14.582 5.5 17.5M12 16a4 4 0 1 0 0-8 4 4 0 0 0 0 8Z"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              ></path>
                              <path
                                d="M12 21a9 9 0 1 0 0-18 9 9 0 0 0 0 18Z"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              ></path>
                            </svg>
                            <span className="text-white-500 text-base  ml-5 false">
                              Stats
                            </span>
                          </div>
                        </button>
                      </a>
                      <a href="/targets">
                        <button className="relative w-full flex items-center px-2.5 py-2 rounded-xl cursor-pointer undefined hover:bg-black-light lg:hover:bg-transparent false lg:border-b lg:border-white-300 lg:rounded-none lg:pl-0 lg:pr-5 lg:py-5 lg:justify-between">
                          <div className="flex items-center text-left">
                            <svg
                              className="w-6 h-6 stroke-current text-white-500 lg:w-5 lg:h-5"
                              stroke="#fff"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M12 13a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              ></path>
                              <path
                                d="M12 17a5 5 0 1 0 0-10 5 5 0 0 0 0 10Z"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              ></path>
                              <path
                                d="M12 21a9 9 0 1 0 0-18 9 9 0 0 0 0 18Z"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              ></path>
                            </svg>
                            <span className="text-white-500 text-base  ml-5 false">
                              Targets
                            </span>
                            <span className="absolute top-1 right-1.5 text-orange text-sm lg:bg-orange-200 lg:px-2.5 lg:py-1 lg:rounded lg:top-1/2 lg:-translate-y-1/2 lg:right-5">
                              Beta
                            </span>
                          </div>
                        </button>
                      </a>
                      <a href="/information">
                        <button className="relative w-full flex items-center px-2.5 py-2 rounded-xl cursor-pointer undefined hover:bg-black-light lg:hover:bg-transparent false lg:border-b lg:border-white-300 lg:rounded-none lg:pl-0 lg:pr-5 lg:py-5 lg:justify-between">
                          <div className="flex items-center text-left">
                            <svg
                              className="w-6 h-6 stroke-current text-white-500 lg:w-5 lg:h-5"
                              viewBox="0 0 24 24"
                              stroke="#fff"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M13 7a2 2 0 0 1 2 2v12l-5-3-5 3V9a2 2 0 0 1 2-2h6Z"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              ></path>
                              <path
                                d="M9.265 4A2 2 0 0 1 11 3h6a2 2 0 0 1 2 2v12l-1-.6"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              ></path>
                            </svg>
                            <span className="text-white-500 text-base  ml-5 false">
                              Information
                            </span>
                          </div>
                        </button>
                      </a>
                      <a href="/tbots">
                        <button className="relative w-full flex items-center px-2.5 py-2 rounded-xl cursor-pointer undefined hover:bg-black-light lg:hover:bg-transparent false lg:border-b lg:border-white-300 lg:rounded-none lg:pl-0 lg:pr-5 lg:py-5 lg:justify-between">
                          <div className="flex items-center text-left">
                            <svg
                              className="w-6 h-6 stroke-current text-white-500 lg:w-5 lg:h-5"
                              viewBox="0 0 24 24"
                              stroke="#fff"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="m15 10-4 4 6 6 4-16-18 7 4 2 2 6 3-4"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              ></path>
                            </svg>
                            <span className="text-white-500 text-base  ml-5 false">
                              Telegram bots
                            </span>
                          </div>
                        </button>
                      </a>
                      <a href="/messages">
                        <button className="relative w-full flex items-center px-2.5 py-2 rounded-xl cursor-pointer undefined hover:bg-black-light lg:hover:bg-transparent false lg:border-b lg:border-white-300 lg:rounded-none lg:pl-0 lg:pr-5 lg:py-5 lg:justify-between">
                          <div className="flex items-center text-left">
                            <svg
                              className="w-6 h-6 stroke-current text-white-500 lg:w-5 lg:h-5"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="m3 20 1.3-3.9c-1.124-1.662-1.53-3.63-1.144-5.538.386-1.908 1.54-3.626 3.244-4.836 1.704-1.21 3.845-1.827 6.024-1.739 2.179.089 4.248.877 5.821 2.22 1.574 1.342 2.546 3.147 2.735 5.078.19 1.931-.417 3.858-1.706 5.422-1.29 1.564-3.173 2.659-5.302 3.08-2.13.422-4.358.142-6.272-.787L3 20"
                                stroke="#fff"
                                strokeOpacity=".5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              ></path>
                              <path
                                d="M12 12v.01M8 12v.01M16 12v.01"
                                stroke="#fff"
                                strokeOpacity=".5"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              ></path>
                            </svg>
                            <span className="text-white-500 text-base  ml-5 false">
                              Messages
                            </span>
                          </div>
                        </button>
                      </a>
                      <div className="hidden lg:block" href="undefined">
                        <button className="relative w-full flex items-center px-2.5 py-2 rounded-xl cursor-pointer undefined hover:bg-black-light lg:hover:bg-transparent false lg:border-b lg:border-white-300 lg:rounded-none lg:pl-0 lg:pr-5 lg:py-5 lg:justify-between">
                          <div className="flex items-center text-left">
                            <svg
                              className="w-6 h-6 stroke-current text-white-500 lg:w-5 lg:h-5"
                              viewBox="0 0 20 20"
                              fill="none"
                              stroke="#fff"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M8.333 14.167a5.833 5.833 0 1 0 0-11.667 5.833 5.833 0 0 0 0 11.667ZM17.5 17.5l-5-5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              ></path>
                            </svg>
                            <span className="text-white-500 text-base  ml-5 false">
                              Red Rhino Account search
                            </span>
                          </div>
                          <svg
                            className="stroke-current text-white-500 hidden lg:flex"
                            width="8"
                            height="14"
                            stroke="#fff"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="m1 13 6-6M1 1l6 6"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>
                          </svg>
                        </button>
                      </div>
                      <a className="hidden lg:block" href="/">
                        <button className="relative w-full flex items-center px-2.5 py-2 rounded-xl cursor-pointer undefined hover:bg-black-light lg:hover:bg-transparent bg-black-light lg:bg-transparent lg:border-b lg:border-white-300 lg:rounded-none lg:pl-0 lg:pr-5 lg:py-5 lg:justify-between">
                          <div className="flex items-center text-left">
                            <svg
                              className="w-6 h-6 stroke-current text-white-500 lg:w-5 lg:h-5"
                              width="24"
                              height="24"
                              stroke="#fff"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M14 8V6a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h7a2 2 0 0 0 2-2v-2M7 12h14m0 0-3-3m3 3-3 3"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              ></path>
                            </svg>
                            <span className="text-white-500 text-base  ml-5 text-white-900">
                              Log out
                            </span>
                          </div>
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="w-full lg:px-5 lg:pb-7.5">
                  <a target="_blank" href="https://oldRRN.redrhino.io">
                    <button className="flex justify-center items-center text-center text-base font-bold text-white rounded-mini sm:text-sm outline-none px-5 py-3 bg-light-purple hover:bg-hover-purple active:bg-active-purple bg-purple rounded-mini px-3 w-full justify-between mb-2.5 lg:mb-0">
                      <span className="text-white text-base font-normal">
                        Old interface
                      </span>
                      <svg
                        className="h-5"
                        viewBox="0 0 46 28"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1 14C1 6.82 6.82 1 14 1h18c7.18 0 13 5.82 13 13s-5.82 13-13 13H14C6.82 27 1 21.18 1 14Z"
                          stroke="#fff"
                        ></path>
                        <circle cx="14" cy="14" r="9" fill="#fff"></circle>
                      </svg>
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </header>
          <div className="flex flex-col flex-1 w-full max-w-desktop-login mt-7.5 sm:mt-0">
            <div className="flex flex-1 items-center justify-between w-full sm:flex-col px-3">
              <div className="flex flex-1 flex-col items-start mr-10 sm:mr-0 sm:items-stretch sm:max-w-full sm:p-5">
                <div className="flex flex-col sm:flex-1">
                  <span className="inline-block text-two-half text-white mb-10 sm:mb-7.5 sm:text-2xl">
                    Registration <br /> in OxCash Pro{" "}
                  </span>
                  <div className="flex flex-col mb-10 sm:mb-7.5">
                    <div className="w-full relative flex flex-col flex-grow-0 flex-shrink-0">
                      <label className="mb-2.5 text-white-500 sm:text-sm">
                        Your upline
                      </label>
                      <div className="w-full relative">
                        <input
                          className="w-full bg-white-100 border-2 border-transparent rounded-mini py-3 px-5 text-white outline-none focus:border-2 focus:border-main-blue focus:bg-transparent false"
                          onChange={(e) =>
                            this.setState({ referrerId: e.target.value })
                          }
                          value={this.state.referrerId}
                          type="number"
                          placeholder="Upline"
                          id="referralInput"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col space-y-5">
                    <div className="flex flex-col items-start">
                      <div className="flex ">
                        <i className="las la-info-circle text-3xl text-base"></i>
                        <div
                          className="flex flex-wrap items-center ml-2.5 leading-5 text-base whitespace-nowrap"
                          id="regbalance"
                        >
                          <span>Balance</span>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col items-start">
                      <div className="flex ">
                        <i className="las la-info-circle text-3xl text-base"></i>
                        <div
                          className="flex flex-wrap items-center ml-2.5 leading-5 text-base whitespace-nowrap"
                          id="regregistration"
                        >
                          <span>Registration - 200 TRX</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex">
                  <button
                    className="flex justify-center items-center text-center text-base font-bold text-white rounded-mini sm:text-sm outline-none px-5 py-3 bg-blue-600 hover:bg-blue-400 active:bg-active-red-600 mt-10 py-5 !px-10 sm:py-3  sm:mt-7.5"
                    onClick={this.handleRegister}
                    disabled={this.state.loading}
                    id="nextButton"
                  >
                    Register{" "}
                  </button>
                </div>
              </div>
              <div className="bg-white-100 rounded p-10 max-w-desktop-reg-info-card w-full flex-shrink h-auto flex flex-col sm:min-h-auto sm:mb-10 sm:max-w-full">
                <div className="flex flex-1 items-start w-full">
                  <div className="flex flex-col">
                    <span className="flex items-center text-white text-2xl font-bold mb-5 sm:mb-2.5">
                      <svg
                        className="inline w-6 h-6 mr-5"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect width="24" height="24" rx="12" fill="#fff"></rect>
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M11.25 8a.75.75 0 0 1 .75-.75h.01a.75.75 0 0 1 0 1.5H12a.75.75 0 0 1-.75-.75ZM10.25 12a.75.75 0 0 1 .75-.75h1a.75.75 0 0 1 .75.75v3.25H13a.75.75 0 0 1 0 1.5h-1a.75.75 0 0 1-.75-.75v-3.25H11a.75.75 0 0 1-.75-.75Z"
                          fill="#343A4D"
                        ></path>
                      </svg>
                      How to Invest in OxCash Pro
                    </span>
                    <div className="flex flex-col">
                      <span className="">
                        Access the website via TronLink dapp browser (”Discover”
                        button in Tokenpoket) or with TronLink extension
                        installed.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <footer className="flex z-10  items-center  justify-between  py-10 w-full sm:justify-start sm:items-start sm:flex-col sm:px-5 sm:pt-7.5 sm:pb-12 sm:mt-0">
              <div className="flex items-center sm:w-full sm:items-start sm:text-left sm:flex-col sm:order-2">
                <span className="text-white-500 text-base font-normal mr-2.5 sm:text-sm sm:mb-2.5 sm:mr-0">
                  © 2022 All Rights Reserved
                </span>
              </div>
              <div className="flex space-x-14 sm:w-full sm:space-y-14 sm:space-x-0 sm:order-1 sm:mb-7.5">
                <div className="flex space-x-5 w-full">
                  <a
                    className="w-6 h-6 sm:w-7 sm:h-7 flex justify-center items-center rounded-full bg-white-100 hover:bg-white-300"
                    target="_blank"
                    href="https://t.me/oxcashnetwork"
                  >
                    <svg
                      className="w-3 h-3 sm:w-4 sm:h-4"
                      viewBox="0 0 14 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M11.915.776.841 5.168a.4.4 0 0 0 .03.755l2.79.864 1.133 3.503a.4.4 0 0 0 .653.17l1.64-1.523 3.11 2.29a.4.4 0 0 0 .627-.231l2.166-9.294a.8.8 0 0 0-1.075-.926Zm-6.483 6.74-.346 2.072-.8-2.901 7.816-5.1-6.67 5.929Z"
                        fill="#fff"
                      ></path>
                    </svg>
                  </a>
                </div>
              </div>
            </footer>
          </div>
        </div>
      </div>
    );
  }
}
 
export default withWrapper(Register);