import React, { Component } from 'react';
import getContract from '../../services/contract';
import { ethers } from 'ethers';
import levelPrices from '../../services/levelPrice';
import getWallet from '../../services/wallet';
import { getActivities, getPartnersActivities, sleep } from '../../services/activities';
import Slot from './tree/slot';
import { TransactionService } from '../../services/transactionService';
import withWrapper from '../../helpers/withWrapper';
import { ToastContainer, toast } from 'react-toastify';
import { getLastActivities } from '../../services/activities';

class Dashboard extends Component {

    state = {
        levelBought: 0,
        reinvestCounts: new Array(15).fill(0),
        dataLoaded: false,
        activities: [],
        userAddress: '',
        partners: 0,
        userId: 0
    };

    constructor(props) {
        super(props);
        const totalLevels = 15;
        const treeNodes = 31;
        let idsArray = new Array(totalLevels).fill(0).map(() => new Array(treeNodes).fill(0));
        this.state.idsWithLevels = idsArray;
        if(props.router.params.id) {
            this.state.userId = props.router.params.id;
        } else {
            this.state.userId = 1;
        }
        this.props.onSetUserId(this.state.userId);
    }

    notify = (message) => toast(message);

    initializeState = async (address) => {
        const contract = await getContract();
        const tronWeb = await getWallet();
        const userId = this.state.userId;
        let userAddress;
        userAddress = await contract.getAddressById(userId).call()
        userAddress = tronWeb.address.fromHex(userAddress.addr ? userAddress.addr : userAddress);
        // if(!address) userAddress = await contract.getAddressById(userId).call();
        // else userAddress = address;

        const totalValueInvestedBN = await contract.totalValueInvested().call();
        const totalValue = ethers.BigNumber.from(totalValueInvestedBN.div(ethers.BigNumber.from("1000000"))).toNumber();
        const totalMembers = ethers.BigNumber.from(await contract.lastIDCount().call()).toNumber();

        const userInfoResponse = await contract.userInfos(userAddress).call();
        const levelBought = ethers.BigNumber.from(userInfoResponse["levelBought"]).toNumber();
        const partners = ethers.BigNumber.from(userInfoResponse["partners"]).toNumber();
        const userTotalIncome = ethers.BigNumber.from(ethers.BigNumber.from(userInfoResponse["totalIncome"]).div(ethers.BigNumber.from("1000000"))).toNumber();

        const reinvestCountsResponse = await contract.getReinvestCounts(userAddress).call();
        const reinvestCounts = reinvestCountsResponse.counts ? reinvestCountsResponse.counts.map(c => ethers.BigNumber.from(c).toNumber()) : (reinvestCountsResponse.map(c => ethers.BigNumber.from(c).toNumber()));

        let userWithLevels = this.state.idsWithLevels;
        try {
            await sleep(2000)
            this.setState({ userId, userAddress, userTotalIncome, totalMembers, totalValue,
                levelBought, reinvestCounts, dataLoaded: true, partners });
          const ids = await contract.getAllLevelTrees(userAddress).call();
        //   toast.info(ids.toString())
          
          for(let i = 0; i < 15; i++) {
            if(ids.tree) {
                ids.tree[i].forEach((response, index) => {
                    const id = ethers.BigNumber.from(response).toNumber();
                    userWithLevels[i][index] = id;
                  });
            } else {
                ids[i].forEach((response, index) => {
                    const id = ethers.BigNumber.from(response).toNumber();
                    userWithLevels[i][index] = id;
                });
            }
          }
          this.setState({ userId, userAddress, userTotalIncome, totalMembers, totalValue,
            levelBought, reinvestCounts, idsWithLevels: userWithLevels, dataLoaded: true, partners });
            toast.info("Tree Loaded")
        } catch (error) {
        //   console.log(error);
        //   toast.error(error)
          await this.initializeState("")
          return;
        }
    }

    async componentDidMount() {
        const tronWeb = await getWallet();
        const promise = this.initializeState();
        await toast.promise(promise, {
            pending: "Fetching dashboard data please wait...",
            success: "Data loaded",
            error: "Error fetching data",
        });
        const activities = this.getActivitiesData();
        await toast.promise(activities, {
            pending: "Fetching transactions please wait...",
            success: "Data loaded",
            error: "Error fetching data",
        });
    }

    getActivitiesData = async () => {
        const activities = await getLastActivities();
        this.setState({activities});
    }

    handleBuyLevel = async (level) => {
        const wallet = await getWallet();
        if(!wallet && wallet.defaultAddress == false) {
            alert("Please connect your wallet");
            return;
        }
        const userBrowserAddresses = wallet.defaultAddress.base58;
        if(this.state.userAddress !== userBrowserAddresses) {
            alert("You can't Buy other person's Slot, if its yours then connect your wallet with this ID");
            return;
        }
        try {
            var transactionService = new TransactionService(wallet.defaultAddress.base58);
            const buyLevelPromise = transactionService.buyLevel(level);
            await toast.promise(
                buyLevelPromise,
                {
                  pending: 'Level Upgrade in process',
                  success: 'Level Upgrade Completed! 👌',
                  error: 'Level Upgrade failed '
                }
            )
            await this.initializeState(wallet.defaultAddress.base58);
        } catch (error) {
            alert(error)
        }
    }

    getIdClass = (id) => {
        return (id == 0) ? "bg-white-100" : "bg-white";
    }

    handleCopy = () => {
        if (!navigator.clipboard) {
            this.notify("Copying not supported in this dapp browser");
        } else {
            navigator.clipboard.writeText(this.state.userAddress).then(() => {
                this.notify("Copied to clipboard");
            })
        }
    }

    handleCopyContract = () => {
        if (!navigator.clipboard) {
            this.notify("Copying not supported in this dapp browser");
        } else {
            navigator.clipboard.writeText("TJpqgh8tLA1iFkG8xTSEMrBL1uL1zKbMLg").then(() => {
                this.notify("Copied to clipboard");
            })
        }
    }

    handleRefLinkCopy = () => {
        if (!navigator.clipboard) {
            this.notify("Copying not supported in this dapp browser");
        } else {
            navigator.clipboard.writeText('https://pro.ox.cash/register?referral=' + this.state.userId).then(() => {
                this.notify("Copied to clipboard");
            })
        }
    }

    handleSignUpWithRefId = () => {
        this.props.router.navigate('/register?referral=' + this.state.userId);
    }

    render() { 
        return (
            <div className="flex flex-shrink w-full flex-col items-center min-h-screen bg-main pt-20 sm:pt-14">
                {/* <ToastContainer position="bottom-right" /> */}
                    <div className="flex relative flex-1 py-2 pt-8.5 flex-col justify-between w-full px-10 sm:px-0 sm:pt-7.5">
                        {/* Top Panel, Matrix levels and all activies inside below div */}
                        <div className="flex flex-col w-full space-y-10 sm:space-y-5">

                            {/* Top panel of Dashboard mobile view inside below div */}
                            <div
                                className="flex w-full items-start justify-between space-x-10 lg:space-x-2.5 sm:space-x-0 sm:space-y-5 sm:flex-col sm:px-5">
                                <div className="flex items-start flex-shrink-0 sm:flex-shrink">
                                    <div
                                        className="flex-shrink-0 relative w-30 h-30 rounded-full cursor-pointer sm:w-15 sm:h-15">
                                        <div className="profile-logo-box w-26 h-16 sm:h-14 sm:w-14 false rounded-full p-2">
                                            <img
                                                src="/images/logo.png" />
                                        </div>

                                    </div>
                                    <div className="flex flex-col items-start ml-6 h-full justify-center my-auto">
                                        <div className="flex justify-center items-center mb-1 sm:mb-2.5"><span
                                                className="text-white font-bold text-3xl mr-2.5 sm:text-xl cursor-pointer showContractId">ID
                                                # {this.state.userId} </span></div>
                                        <div className="flex flex-col items-start">
                                            <div className="flex items-center mb-1">
                                                <span className="text-white font-bold mr-2.5 text-base sm:text-sm showWalletAddress">
                                                {(this.state.userAddress != '') ? (this.state.userAddress.slice(0, 4) + '...' + this.state.userAddress.slice(this.state.userAddress.length - 4, this.state.userAddress.length))
                                                        : ''}
                                                    </span>
                                                    <button className="copyAddress" onClick={this.handleCopy}><svg className="w-5 h-5"
                                                        viewBox="0 0 20 20" fill="#fff"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M15 6.667H8.332c-.92 0-1.667.746-1.667 1.666V15c0 .92.746 1.667 1.667 1.667h6.666c.92 0 1.667-.747 1.667-1.667V8.333c0-.92-.746-1.666-1.667-1.666Z">
                                                        </path>
                                                        <path clipRule="evenodd"
                                                            d="M3.469 3.468A2.167 2.167 0 0 1 5 2.833h6.666A2.167 2.167 0 0 1 13.834 5v1.667a.5.5 0 0 1-1 0V5a1.167 1.167 0 0 0-1.167-1.167H5.001A1.167 1.167 0 0 0 3.834 5v6.667a1.167 1.167 0 0 0 1.167 1.166h1.666a.5.5 0 1 1 0 1H5.001a2.167 2.167 0 0 1-2.167-2.166V5c0-.575.228-1.126.635-1.532Z">
                                                        </path>
                                                    </svg></button></div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="relative flex flex-grow max-w-500px w-full h-full flex-col p-5 justify-between bg-black-light rounded sm:max-w-full theme-card-bg">
                                    <div className="flex items-center"><span
                                            className=" text-base sm:text-sm">Personal link</span></div>
                                    <div className="md:flex flex-wrap justify-between false">
                                        <span
                                            className="text-blue-600 text-lg font-bold lg:mb-2.5 sm:text-xl refferalLink break-all"
                                            id="refferalLink">https://pro.ox.cash/register?referral={this.state.userId}</span>
                                        <div className="flex space-x-2.5">
                                            <button onClick={this.handleRefLinkCopy}
                                                className="flex justify-center items-center text-center text-base font-bold text-white rounded-mini sm:text-sm outline-none px-0 py-0 bg-transparent !leading-30px copyReferral">
                                                <svg className="w-5 h-5 stroke-current text-white" viewBox="0 0 20 20"
                                                    fill="#fff" xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M15 6.667H8.332c-.92 0-1.667.746-1.667 1.666V15c0 .92.746 1.667 1.667 1.667h6.666c.92 0 1.667-.747 1.667-1.667V8.333c0-.92-.746-1.666-1.667-1.666Z">
                                                    </path>
                                                    <path clipRule="evenodd"
                                                        d="M3.469 3.468A2.167 2.167 0 0 1 5 2.833h6.666A2.167 2.167 0 0 1 13.834 5v1.667a.5.5 0 0 1-1 0V5a1.167 1.167 0 0 0-1.167-1.167H5.001A1.167 1.167 0 0 0 3.834 5v6.667a1.167 1.167 0 0 0 1.167 1.166h1.666a.5.5 0 1 1 0 1H5.001a2.167 2.167 0 0 1-2.167-2.166V5c0-.575.228-1.126.635-1.532Z">
                                                    </path>
                                                </svg>
                                            </button>
                                        </div>
                                        <div
                                            className="flex justify-between items-center text-left border-t border-white-100 w-full mt-2.5 pt-2.5">
                                            <div className="flex flex-wrap mr-1.5">
                                                <div className="mr-1.5">Not a member yet? </div>
                                                <div>Sign up with <span className="whitespace-nowrap">this upline</span>
                                                </div>
                                            </div>
                                            <button onClick={this.handleSignUpWithRefId} className="flex justify-center items-center text-base rounded-mini sm:text-sm outline-none py-0 px-2.5 bg-blue-600 text-black rounded !leading-30px hover:bg-blue-500 active:bg-active-main-blue w-max flex-shrink-0">Sign up</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Top panel of Dashboard mobile view inside above div */}

                            {/* Top panel Partners, Team, Profit ration, Profits View inside below div */}
                            <div className="flex w-full mt-15 sm:mt-5 sm:px-5">
                                <div className="flex w-full flex-wrap lg:flex-col">
                                    <div
                                        className="flex mr-10 space-x-10 lg:mr-0 lg:mt-10 sm:mt-5 sm:space-x-5 lg:order-2 sm:overflow-auto sm:w-full">
                                        <div
                                            className="lg:flex-grow flex flex-col p-5 bg-black-light rounded desktop-infoblock-base sm:mobile-infoblock-base theme-card-bg">
                                            <div className="flex items-center"><span
                                                    className="text-base sm:text-sm sm:whitespace-nowrap">Partners</span>
                                            </div><span
                                                className="text-2xl font-bold sm:text-xl mt-5 sm:mt-2.5">{this.state.partners}</span>

                                        </div>
                                        <div
                                            className="lg:flex-grow flex flex-col p-5 bg-black-light rounded desktop-infoblock-base sm:mobile-infoblock-base theme-card-bg">
                                            <div className="flex items-center"><span
                                                    className="text-base sm:text-sm sm:whitespace-nowrap">Slots Unlocked</span>
                                            </div><span className="text-2xl font-bold sm:text-xl mt-5 sm:mt-2.5"
                                                id="yourTeamTreeCount">{this.state.levelBought}</span>

                                        </div>
                                        <div
                                            className="lg:flex-grow sm:max-w-full flex flex-col p-5 bg-black-light rounded desktop-infoblock-base sm:mobile-infoblock-base theme-card-bg">
                                            <div className="flex items-center"><span
                                                    className="text-base sm:text-sm sm:whitespace-nowrap">Profit
                                                    Ratio</span>
                                            </div><span
                                                className="text-2xl font-bold sm:text-xl mt-5 sm:mt-2.5">45 %
                                            </span>

                                        </div>
                                    </div>
                                    <div
                                        className="flex flex-1 max-h-140px sm:max-w-full overflow-hidden w-full relative justify-start items-end bg-black-light rounded lg:max-w-full desktop-infoblock-chart-base theme-card-bg">
                                        <div
                                            className="flex-shrink-0 flex flex-col p-5 rounded desktop-infoblock-base sm:mobile-infoblock-base">
                                            <div className="flex items-center"><span
                                                    className="text-base sm:text-sm sm:whitespace-nowrap">Profits</span>
                                            </div><span className="text-2xl font-bold sm:text-xl mt-5 sm:mt-2.5">
                                                {this.state.userTotalIncome} TRX</span>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Top panel Partners, Team, Profit ration, Profits View inside above div */}

                            {/* Matrix name inside below div */}
                            <div className="flex flex-wrap justify-between">
                                <div className="flex flex-col w-full flex-wrap sm:px-5">
                                    <div className="flex items-center mb-1.5 sm:mb-2.5">
                                        <div className="flex">
                                            <a className="mr-1.5 text text-white-300 sm:text-sm" href="/dashboard?user=1">
                                                <span className="hover:text-white-500"></span>
                                            </a>
                                        </div>
                                        <span className="text text-white whitespace-nowrap sm:text-sm"
                                            style={{display: 'none'}} id="levelBoughtText">OxCash Pro </span>
                                        <span className="text text-white whitespace-nowrap ml-1.5 sm:text-sm">
                                            <span className="inline sm:hidden" style={{display: 'none'}}
                                                id="levelBoughtInfo">(0 out of 15 slots)</span>
                                        </span>
                                    </div>
                                    <div className="w-full flex justify-between flex-wrap">
                                        <div className="flex items-center"><span
                                                className="text-two-half text-white font-medium mr-2 sm:text-2xl whitespace-nowrap">Ox
                                                Cash </span></div>

                                    </div>
                                </div>
                            </div>
                            {/* Matrix name inside above div */}

                            {/* Matrix Tree and Levels inside below div */}
                            <div className="flex overflow-hidden relative w-full flex-col bg-gray-800 rounded p-7.5 pb-5 sm:p-5 sm:pl-2.5 sm:pr-2.5 sm:rounded-none theme-card-bg">
                                <div className="flex z-10 flex-wrap -m-2 sm:-mx-px mb-7.5 sm:justify-around">
                                    { this.state.idsWithLevels.map((ids, index) => {
                                        const isActive = (index+1) <= this.state.levelBought;

                                        return <Slot key={index} ids={ids} index={index}
                                            levelPrices={levelPrices}
                                            reinvestCounts={this.state.reinvestCounts}
                                            onSlotClick={this.props.onSlotClick}
                                            onBuyLevel={this.handleBuyLevel}
                                            isActive={isActive}
                                        />
                                    })}
                                </div>
                                <div className="flex items-center flex-wrap z-10">
                                    <div
                                        className="flex items-center text-white-500 mb-5 mr-7.5 sm:items-start sm:mb-2.5 sm:pr-2.5 sm:mr-0 sm:w-1/2 sm:order-2">
                                        <div className="w-5 h-5 flex-shrink-0 mr-2.5 rounded-full bg-white"></div><span
                                            className="text-base sm:text-sm">Direct partner</span>
                                    </div>
                                    <div
                                        className="flex items-center text-white-500 mb-5 mr-7.5 sm:items-start sm:mb-2.5 sm:pr-2.5 sm:mr-0 sm:w-1/2 sm:order-4">
                                        <div className="w-5 h-5 flex-shrink-0 mr-2.5 rounded-full bg-light-blue"></div><span
                                            className="text-base sm:text-sm">Spillover from above</span>
                                    </div>
                                    <div
                                        className="flex items-center text-white-500 mb-5 mr-7.5 sm:items-start sm:mb-2.5 sm:pr-2.5 sm:mr-0 sm:w-1/2 sm:order-6">
                                        <div className="w-5 h-5 flex-shrink-0 mr-2.5 rounded-full bg-orange"></div><span
                                            className="text-base sm:text-sm">Spillover from below</span>
                                    </div>
                                    <div
                                        className="flex items-center text-white-500 mb-5 mr-7.5 sm:items-start sm:mb-2.5 sm:pr-2.5 sm:mr-0 sm:w-1/2 sm:order-7">
                                        <div className="w-5 h-5 flex-shrink-0 mr-2.5 rounded-full bg-green-500"></div><span
                                            className="text-base sm:text-sm">Freeflow</span>
                                    </div>
                                    <div
                                        className="flex items-center text-white-500 mb-5 mr-7.5 sm:items-start sm:mb-2.5 sm:pr-2.5 sm:mr-0 sm:w-1/2 sm:order-1">
                                        <svg className="w-5 h-5 mr-2.5 stroke-current text-white" viewBox="0 0 16 16"
                                            fill="none" stroke="#fff" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M6 7.333A2.667 2.667 0 1 0 6 2a2.667 2.667 0 0 0 0 5.333ZM2 14v-1.333A2.667 2.667 0 0 1 4.667 10h2.666A2.667 2.667 0 0 1 10 12.667V14M10.667 2.086a2.667 2.667 0 0 1 0 5.167M14 14v-1.333a2.667 2.667 0 0 0-2-2.567"
                                                strokeWidth="1.333" strokeLinecap="round" strokeLinejoin="round">
                                            </path>
                                        </svg><span className="text-base sm:text-sm">Partners on level</span></div>
                                    <div
                                        className="flex items-center text-white-500 mb-5 mr-7.5 sm:items-start sm:mb-2.5 sm:pr-2.5 sm:mr-0 sm:w-1/2 sm:order-3">
                                        <svg className="w-5 h-5 mr-2.5 stroke-current text-white" viewBox="0 0 20 20"
                                            fill="none" stroke="#2CFF4E" xmlns="http://www.w3.org/2000/svg">
                                            <path clipRule="evenodd"
                                                d="M6.354 3.818a7.25 7.25 0 0 1 10.808 5.28.5.5 0 1 1-.99.137A6.25 6.25 0 0 0 4.551 7h2.115a.5.5 0 0 1 0 1H3.333a.5.5 0 0 1-.5-.5V4.167a.5.5 0 1 1 1 0v2.086a7.25 7.25 0 0 1 2.521-2.435ZM3.265 10.338a.5.5 0 0 1 .564.427A6.25 6.25 0 0 0 15.449 13h-2.116a.5.5 0 1 1 0-1H16.667a.5.5 0 0 1 .5.5v3.333a.5.5 0 1 1-1 0v-2.086a7.25 7.25 0 0 1-13.329-2.845.5.5 0 0 1 .427-.564Z">
                                            </path>
                                        </svg><span className="text-base sm:text-sm">Level Cycle</span></div>
                                </div>
                            </div>
                            {/* Matrix Tree and Levels inside above div */}

                            {/* Activities, Transactions and other info inside below div */}
                            <div className="flex flex-col">
                                <div className="flex items-center sm:px-5">
                                    <span className="text-white text-3xl font-bold sm:text-2xl">Activities</span>
                                </div>
                                <div className="grid grid-cols-3 gap-10 mt-5 lg:grid-cols-1 lg:gap-0 z-10">
                                    <div
                                        className="w-full overflow-hidden flex flex-col col-span-2 p-5 bg-gray rounded h-815px  lg:order-2 sm:rounded-none sm:p-5 sm:max-h-3/4">
                                        <div className="flex flex-col flex-1 sm:overflow-auto">
                                        {this.state.activities.map((a, index) => {
                              return (a.isRegistrationEvent ? 
                                  <div key={index} className="flex items-center justify-between py-5 border-line-gray border-b first:pt-0 w-full sm:py-5 sm:items-start">
                                    <div className="flex items-center sm:items-start justify-start sm:w-full">
                                      <div className="theme-card-bg flex w-10 h-10 items-center justify-center rounded-full flex-shrink-0 sm:w-7.5 sm:h-7.5">
                                        <svg
                                          className="fill-current text-white w-5 h-5"
                                          width="20"
                                          height="20"
                                          fill="#fff"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path d="M7.5 9.167a3.333 3.333 0 1 0 0-6.667 3.333 3.333 0 0 0 0 6.667ZM2.5 17.5v-2.667A3.333 3.333 0 0 1 5.833 11.5h3.334a3.333 3.333 0 0 1 3.333 3.333V17.5"></path>
                                          <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M15.833 6.167a.5.5 0 0 1 .5.5v2h2a.5.5 0 1 1 0 1h-2v2a.5.5 0 1 1-1 0v-2h-2a.5.5 0 0 1 0-1h2v-2a.5.5 0 0 1 .5-.5Z"
                                          ></path>
                                        </svg>
                                      </div>
                                      
                                      <div className="flex ml-5 items-center sm:w-full flex flex-wrap sm:ml-2.5">
                                        <a
                                          className="flex items-center justify-center px-2.5 leading-30px bg-blue-100 hover:bg-main-blue-300 text-main-blue rounded font-medium text-base sm:text-sm w-max order-2 text-sm"
                                          href="dashboard-user.html"
                                        >
                                          ID {a.id}
                                        </a>
                                        <div className="h-full flex flex-wrap justify-center items-center mx-2.5 sm:mx-1.5 ml-0">
                                          <span className="text-white-500 text-base sm:text-sm sm:leading-30px">
                                            new user joined
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="flex items-start">
                                      <div className="flex items-center justify-end whitespace-nowrap h-full">
                                        <a
                                          className="sm:order-2"
                                          target="_blank"
                                          href={"https://tronscan.org/#/transaction/" + a.txId}
                                        >
                                          <svg
                                            className="w-6 h-6"
                                            viewBox="0 0 24 24"
                                            fill="#fff"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              fillRule="evenodd"
                                              clipRule="evenodd"
                                              d="M4.232 7.232A2.5 2.5 0 0 1 6 6.5h5a.5.5 0 0 1 0 1H6A1.5 1.5 0 0 0 4.5 9v9A1.5 1.5 0 0 0 6 19.5h9a1.5 1.5 0 0 0 1.5-1.5v-5a.5.5 0 0 1 1 0v5a2.5 2.5 0 0 1-2.5 2.5H6A2.5 2.5 0 0 1 3.5 18V9a2.5 2.5 0 0 1 .732-1.768Z"
                                            ></path>
                                            <path
                                              fillRule="evenodd"
                                              clipRule="evenodd"
                                              d="M20.354 3.646a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.708-.708l10-10a.5.5 0 0 1 .708 0Z"
                                            ></path>
                                            <path
                                              fillRule="evenodd"
                                              clipRule="evenodd"
                                              d="M14.5 4a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 .5.5v5a.5.5 0 0 1-1 0V4.5H15a.5.5 0 0 1-.5-.5Z"
                                            ></path>
                                          </svg>
                                        </a>
                                        <span className="ml-2.5 text-white-500 text-base sm:text-sm sm:order-1 sm:ml-2.5 sm:mr-1 sm:leading-30px">
                                          {a.time}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  :
                                  <div key={index} className="flex items-center justify-between py-5 border-line-gray border-b first:pt-0 w-full sm:py-5 sm:items-start">
                                    <div className="flex items-center sm:items-start justify-start sm:w-full">
                                      <div className="theme-card-bg flex w-10 h-10 items-center justify-center rounded-full flex-shrink-0 sm:w-7.5 sm:h-7.5">
                                        <svg
                                          className="fill-current text-white w-5 h-5"
                                          viewBox="0 0 20 20"
                                          fill="#ffff"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M3.468 3.468A2.167 2.167 0 0 1 5 2.833h8.334a1.333 1.333 0 0 1 1.333 1.334v2H15A1.333 1.333 0 0 1 16.334 7.5V10a.5.5 0 0 1-1 0V7.5A.333.333 0 0 0 15 7.167H5c-.417 0-.82-.12-1.167-.341V15A1.167 1.167 0 0 0 5 16.167h10a.333.333 0 0 0 .334-.334v-2.5a.5.5 0 0 1 1 0v2.5A1.333 1.333 0 0 1 15 17.168H5A2.166 2.166 0 0 1 2.833 15V5c0-.574.229-1.126.635-1.532ZM3.833 5A1.167 1.167 0 0 0 5 6.167h8.667v-2a.333.333 0 0 0-.333-.333H5A1.167 1.167 0 0 0 3.833 5Z"
                                          ></path>
                                          <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M5 3a2 2 0 0 0-2 2v11a1 1 0 0 0 1 1h11a1 1 0 0 0 1-1V8a1 1 0 0 0-1-1h-1V5a2 2 0 0 0-2-2H5Z"
                                            fillOpacity=".5"
                                          ></path>
                                          <path d="M16.667 10v3.333h-3.334a1.667 1.667 0 0 1 0-3.333h3.334Z"></path>
                                          <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M13.333 10.5a1.167 1.167 0 1 0 0 2.333h2.834V10.5h-2.834Zm-1.532-.365a2.167 2.167 0 0 1 1.532-.635h3.334a.5.5 0 0 1 .5.5v3.333a.5.5 0 0 1-.5.5h-3.334a2.166 2.166 0 0 1-1.532-3.698Z"
                                          ></path>
                                        </svg>
                                      </div>
                                      <div className="flex ml-5 items-center sm:w-full flex flex-wrap sm:ml-2.5">
                                        <a
                                          className="flex items-center justify-center px-2.5 leading-30px bg-blue-100 hover:bg-main-blue-300 text-main-blue rounded font-medium text-base sm:text-sm w-max false text-sm text-red-500"
                                          href="dashboard-user.html"
                                        >
                                          ID {a.id}
                                        </a>
                                        <div className="h-full flex flex-wrap justify-start items-center mx-2.5 sm:mx-1.5 false">
                                          <span className="text-white-500 text-base sm:text-sm sm:leading-20px">
                                            +
                                          </span>
                                          <span className="text-white text-base sm:text-sm mx-1 sm:leading-30px">
                                            {a.amount / 1000000} TRX{" "}
                                          </span>
                                          <span className="text-white-500 text-base sm:text-sm sm:leading-20px">
                                            in
                                            <span className=" text-orange">
                                              {" "}
                                              TRX{" "}
                                            </span>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="flex items-start">
                                      <div className="flex items-center justify-end whitespace-nowrap h-full">
                                        <a
                                          className="sm:order-2"
                                          target="_blank"
                                          href={"https://tronscan.org/#/transaction/" + a.txId}
                                        >
                                          <svg
                                            className="w-6 h-6"
                                            viewBox="0 0 24 24"
                                            fill="#fff"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              fillRule="evenodd"
                                              clipRule="evenodd"
                                              d="M4.232 7.232A2.5 2.5 0 0 1 6 6.5h5a.5.5 0 0 1 0 1H6A1.5 1.5 0 0 0 4.5 9v9A1.5 1.5 0 0 0 6 19.5h9a1.5 1.5 0 0 0 1.5-1.5v-5a.5.5 0 0 1 1 0v5a2.5 2.5 0 0 1-2.5 2.5H6A2.5 2.5 0 0 1 3.5 18V9a2.5 2.5 0 0 1 .732-1.768Z"
                                            ></path>
                                            <path
                                              fillRule="evenodd"
                                              clipRule="evenodd"
                                              d="M20.354 3.646a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.708-.708l10-10a.5.5 0 0 1 .708 0Z"
                                            ></path>
                                            <path
                                              fillRule="evenodd"
                                              clipRule="evenodd"
                                              d="M14.5 4a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 .5.5v5a.5.5 0 0 1-1 0V4.5H15a.5.5 0 0 1-.5-.5Z"
                                            ></path>
                                          </svg>
                                        </a>
                                        <span className="ml-2.5 text-white-500 text-base sm:text-sm sm:order-1 sm:ml-2.5 sm:mr-1 sm:leading-30px">
                                          {a.time}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                )
                            })}
                                        </div>

                                    </div>
                                    <div
                                        className="flex flex-col space-y-10 lg:w-full lg:order-1 lg:mb-10 sm:mb-5 sm:space-y-5 sm:px-5">
                                        <div
                                            className="flex flex-col p-5 bg-black-light rounded desktop-infoblock-base sm:mobile-infoblock-base theme-card-bg">
                                            <div className="flex items-center"><span
                                                    className="text-xl sm:text-sm sm:whitespace-nowrap">Members total</span>
                                            </div>
                                            <span
                                                className="text-white text-2xl font-bold sm:text-xl mt-5 sm:mt-2.5">{this.state.totalMembers}</span>

                                        </div>
                                        <div
                                            className="flex flex-col p-5 bg-black-light rounded desktop-infoblock-base sm:mobile-infoblock-base theme-card-bg">
                                            <div className="flex items-center"><span
                                                    className="text-xl sm:text-sm sm:whitespace-nowrap">Members received, TRX</span>
                                            </div>
                                            <span className="text-white text-2xl font-bold sm:text-xl mt-5 sm:mt-2.5">{this.state.totalValue}</span>

                                        </div>
                                        <div className="bg-gray rounded p-5 flex flex-col flex-1 theme-card-bg">
                                            <div
                                                className="flex justify-between items-center text-xl sm:text-xl">
                                                <span>OxCash Pro Contracts</span>
                                                <button
                                                    className="rounded-full h-5 w-5 justify-center items-center bg-main-blue hidden">
                                                    <svg className="stroke-current text-white" width="8" height="4"
                                                        fill="#fff" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M4 4 0 0h8L4 4Z"></path>
                                                    </svg>
                                                </button>
                                            </div>
                                            <div className="flex flex-col flex-1 justify-between">
                                                <div
                                                    className="flex flex-col border-t border-b border-white-100 pb-2.5 mt-2.5">

                                                    <div
                                                        className="flex justify-between items-center py-2.5 border-b border-white-100 space-y-1.5 last:border-0 last:pb-0">
                                                        <span className="text-base sm:text-sm">OxCash Pro</span>
                                                        <div className="flex justify-end items-center space-x-2.5">
                                                            <span
                                                                className="text-base sm:text-sm">{"TJpqgh8tLA1iFkG8xTSEMrBL1uL1zKbMLg".substr(0, 4) + "..." + "TJpqgh8tLA1iFkG8xTSEMrBL1uL1zKbMLg".substr(-4)}</span>
                                                            <button
                                                                onClick={this.handleCopyContract}>
                                                                <svg className="h-18px w-18px" viewBox="0 0 20 20"
                                                                    fill="#000" xmlns="http://www.w3.org/2000/svg">
                                                                    <path
                                                                        d="M15 6.667H8.332c-.92 0-1.667.746-1.667 1.666V15c0 .92.746 1.667 1.667 1.667h6.666c.92 0 1.667-.747 1.667-1.667V8.333c0-.92-.746-1.666-1.667-1.666Z">
                                                                    </path>
                                                                    <path clipRule="evenodd"
                                                                        d="M3.469 3.468A2.167 2.167 0 0 1 5 2.833h6.666A2.167 2.167 0 0 1 13.834 5v1.667a.5.5 0 0 1-1 0V5a1.167 1.167 0 0 0-1.167-1.167H5.001A1.167 1.167 0 0 0 3.834 5v6.667a1.167 1.167 0 0 0 1.167 1.166h1.666a.5.5 0 1 1 0 1H5.001a2.167 2.167 0 0 1-2.167-2.166V5c0-.575.228-1.126.635-1.532Z">
                                                                    </path>
                                                                </svg>
                                                            </button>
                                                            <a target="_blank"
                                                                href="https://tronscan.org/#/contract/TJpqgh8tLA1iFkG8xTSEMrBL1uL1zKbMLg/code">
                                                                <svg className="h-18px w-18px" viewBox="0 0 20 20"
                                                                    fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path clipRule="evenodd"
                                                                        d="M11.314 3.813a3.446 3.446 0 1 1 4.873 4.874l-3.331 3.331a3.418 3.418 0 0 1-4.88-.001.5.5 0 0 1 .715-.7 2.417 2.417 0 0 0 3.452 0l.004-.004L15.48 7.98a2.446 2.446 0 1 0-3.46-3.46l-.416.417a.5.5 0 1 1-.707-.707l.417-.417Z"
                                                                        fill="#000"></path>
                                                                    <path clipRule="evenodd"
                                                                        d="M8.26 7.225a3.417 3.417 0 0 1 3.765.758.5.5 0 0 1-.715.7 2.417 2.417 0 0 0-3.452 0l-.003.004L4.52 12.02a2.446 2.446 0 0 0 3.46 3.46l.416-.417a.5.5 0 1 1 .708.707l-.417.417a3.446 3.446 0 1 1-4.874-4.874l3.332-3.332a3.417 3.417 0 0 1 1.115-.756Z"
                                                                        fill="#000"></path>
                                                                </svg>
                                                            </a>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="flex flex-col pt-2.5 mt-auto">
                                                    {/* <div
                                                        className="flex flex-col py-2.5 border-b border-white-100 space-y-1.5 last:border-0 last:pb-0">
                                                        <span className="text-base text-white sm:text-sm">Transactions
                                                            made</span><span
                                                            className="text-2xl text-white font-bold sm:text-xl">554302</span>

                                                    </div> */}
                                                    <div
                                                        className="flex flex-col py-2.5 border-b border-white-100 space-y-1.5 last:border-0 last:pb-0">
                                                        <span className="text-base sm:text-sm">Turnover,
                                                            TRX</span><span
                                                            className="text-2xl text-white font-bold sm:text-xl">{this.state.totalValue}</span>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Activities, Transactions and other info inside above div */}
                        </div>
                        {/* Top Panel, Matrix levels and all activies inside above div */}
                        <footer
                            className="flex lg:flex-col items-center mt-auto justify-between lg:justify-start lg:items-start py-10 w-full lg:p-5 lg:pb-9 pb-0 lg:pb-0 z-10">
                            <div className="hidden lg:block mb-7.5">
                                <div className="flex space-x-5 w-full"><a
                                        className="w-6 h-6 sm:w-7 sm:h-7 flex justify-center items-center rounded-full bg-white-100 hover:bg-white-300"
                                        target="_blank" href="https://t.me/oxcashnetwork"><svg
                                            className="w-3 h-3 sm:w-4 sm:h-4" viewBox="0 0 14 12" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path clipRule="evenodd"
                                                d="M11.915.776.841 5.168a.4.4 0 0 0 .03.755l2.79.864 1.133 3.503a.4.4 0 0 0 .653.17l1.64-1.523 3.11 2.29a.4.4 0 0 0 .627-.231l2.166-9.294a.8.8 0 0 0-1.075-.926Zm-6.483 6.74-.346 2.072-.8-2.901 7.816-5.1-6.67 5.929Z"
                                                fill="#fff"></path>
                                        </svg></a>

                                </div>
                            </div><span className="text-white-500 text-xs font-normal lg:mb-2.5">© 2022 All Rights
                                Reserved</span>
                        </footer>
                    </div>
            </div>
        );
    }
}
 
export default withWrapper(Dashboard);