import React, { Component } from 'react';
import withRouter from '../../helpers/withWrapper';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import getContract from '../../services/contract';
import { ethers } from 'ethers';

class UniLevel extends Component {
    state = {
        data: [],
      };
    
      constructor(props) {
        super(props);
    
        this.state.userId = props.router.params.id;
      }
    
      async componentDidMount() {
        await this.loadData(this.state.userId);
      }

      loadData = async (id) => {
        const contract = await getContract();
        const promise = contract.getUniLevels(id).call();
        const response = await toast.promise(promise, {
          pending: "Fetching Uni Levels please wait...",
          success: "Data loaded",
          error: "Error fetching data",
        });
        let data = [];
        let last_result = 0;
        for(let i=0; i < response.length; i++) {
          let result = ethers.BigNumber.from(response[i]).toNumber();

          if(last_result == result || result == 0)
            continue;

          last_result = result;
          data.push(result);
        }
        data.push(id);
        this.setState({ data });
      }
    
      async componentWillUnmount() {
        toast.dismiss();
      }
      notify = (message) => toast(message);
    
      handleCopy = (address) => {
        navigator.clipboard.writeText(address);
        this.notify("Copied to clipboard");
      }
    
      render() {
        return (
          <>
            <div className="flex flex-shrink w-full flex-col items-center min-h-screen bg-main pt-20 sm:pt-14">
              <div className="flex relative flex-1 py-2 pt-8.5 flex-col justify-between w-full px-10 sm:px-0 sm:pt-7.5">
                <main className="flex flex-1 w-full">
                  <div className="flex flex-col w-full space-y-10 sm:space-y-7.5">
                    <div className="flex flex-col">
                      <div className="flex mb-7.5 sm:px-5 sm:justify-between">
                        <span className="text-3xl text-white font-medium sm:text-2xl">
                          Uni Levels
                        </span>
                      </div>
                      <div className="flex flex-col mb-5 w-full sm:px-5">
                        <div className="flex-1 z-10 flex flex-col w-full flex-col bg-black-light rounded overflow-hidden h-full min-h-90 sm:max-h-3/4">
                          <div className="overflow-auto">
                            <table className="min-w-max w-full table-auto border-white-100">
                              <thead>
                                <tr className="text-white-500 text-xs font-normal border-b border-white-100">
                                  <th className="p-6 xl:p-4 lg:p-6 sm:p-3 text-left">
                                    #
                                  </th>
                                  {/* <th className="p-6 xl:p-4 lg:p-6 sm:p-3 text-left">
                                    Address
                                  </th> */}
                                  <th className="p-6 xl:p-4 lg:p-6 sm:p-3 text-left">
                                    ID
                                  </th>
                                  {/* <th className="p-6 xl:p-4 lg:p-6 sm:p-3 text-center">Referrar
                                                                    Earning</th> */}
                                  <th className="p-6 xl:p-4 lg:p-6 sm:p-3 text-left">
                                    
                                  </th>
                                </tr>
                              </thead>
                              <tbody className="text-gray-600 text-sm font-light">
                                {this.state.data.map((d, i) => {
                                  return (
                                    <tr
                                      key={i}
                                      className="border-b border-white-100 whitespace-nowrap last:border-b-0"
                                    >
                                      <td className="p-6 xl:p-4 lg:p-6 sm:p-3 whitespace-nowrap text-left text-white-500 font-medium text-xs">
                                        {i+1}
                                      </td>
                                      {/* <td className="p-6 xl:p-4 lg:p-6 sm:p-3 whitespace-nowrap text-left">
                                        <div className="flex items-center space-x-2.5">
                                          <span className="text-white font-medium text-xs">
                                            {d.address}
                                          </span>
                                          <button onClick={() => this.handleCopy(d.address)}>
                                            <svg
                                              className="h-18px w-18px"
                                              viewBox="0 0 20 20"
                                              fill="#fff"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path d="M15 6.667H8.332c-.92 0-1.667.746-1.667 1.666V15c0 .92.746 1.667 1.667 1.667h6.666c.92 0 1.667-.747 1.667-1.667V8.333c0-.92-.746-1.666-1.667-1.666Z"></path>
                                              <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M3.469 3.468A2.167 2.167 0 0 1 5 2.833h6.666A2.167 2.167 0 0 1 13.834 5v1.667a.5.5 0 0 1-1 0V5a1.167 1.167 0 0 0-1.167-1.167H5.001A1.167 1.167 0 0 0 3.834 5v6.667a1.167 1.167 0 0 0 1.167 1.166h1.666a.5.5 0 1 1 0 1H5.001a2.167 2.167 0 0 1-2.167-2.166V5c0-.575.228-1.126.635-1.532Z"
                                              ></path>
                                            </svg>
                                          </button>
                                        </div>
                                      </td> */}
                                      <td className="p-6 xl:p-4 lg:p-6 sm:p-3 whitespace-nowrap text-left">
                                        <a className="flex items-center justify-center px-2.5 leading-30px bg-blue-100 hover:bg-red-600 text-red-600 hover:text-white rounded w-max text-sm"
                                        >
                                          ID
                                          {d}
                                        </a>
                                      </td>
                                      {/* <td className="p-6 xl:p-4 lg:p-6 sm:p-3 whitespace-nowrap text-left text-white font-medium text-xs">
                                                                        TRX</td> */}
                                      <td className="p-4 text-left whitespace-nowrap sm:p-3">
                                          <div className="text-white text-sm ">
                                              <button
                                                  className={`flex justify-center items-center text-center text-base text-black rounded-mini sm:text-sm outline-none py-0 px-2.5 bg-red-300 rounded !leading-30px hover:bg-blue-500 active:bg-active-main-blue w-max flex-shrink-0`}
                                                  onClick={() => {
                                                      this.loadData(d)
                                                  }}
                                              >
                                                  View
                                              </button>
                                          </div>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </main>
                <footer className="flex lg:flex-col items-center mt-auto justify-between lg:justify-start lg:items-start py-10 w-full lg:p-5 lg:pb-9 pb-0 lg:pb-0 z-10">
                  <div className="hidden lg:block mb-7.5">
                    <div className="flex space-x-5 w-full">
                      <a
                        className="w-6 h-6 sm:w-7 sm:h-7 flex justify-center items-center rounded-full bg-white-100 hover:bg-white-300"
                        target="_blank"
                        href="https://t.me/oxcashnetwork"
                      >
                        <svg
                          className="w-3 h-3 sm:w-4 sm:h-4"
                          viewBox="0 0 14 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M11.915.776.841 5.168a.4.4 0 0 0 .03.755l2.79.864 1.133 3.503a.4.4 0 0 0 .653.17l1.64-1.523 3.11 2.29a.4.4 0 0 0 .627-.231l2.166-9.294a.8.8 0 0 0-1.075-.926Zm-6.483 6.74-.346 2.072-.8-2.901 7.816-5.1-6.67 5.929Z"
                            fill="#fff"
                          ></path>
                        </svg>
                      </a>
                    </div>
                  </div>
                  <span className="text-white-500 text-xs font-normal lg:mb-2.5">
                    © 2022 All Rights Reserved
                  </span>
                </footer>
              </div>
            </div>
          </>
        );
      }
}
 
export default withRouter(UniLevel);